
/**
 * 动态添加路由
 * author:qlx
 */
const addRoutes = {
  state: {
    rootRoute: [],
    routeParam: [] // 这里必须持久化参数,重新生成路由,因为里边的require方法没法持久化
  },
  mutations: {

  },
  actions: {

  }
}
export default addRoutes
