/**
 * 缓存菜单信息
 *
 */
const addUserInfo = {
  state: {
    ip: '',
    port: '',
    pwd: '',
    userName: '',
    userId: '',
    lnglat: [],
    hdVideo: null
  },
  mutations: {
    addUserInfo (state, param) {
      if (param) {
        state.userId = param.id
        state.hdVideo = param.hdVideo
        state.userName = param.loginName
      }
    },
    addUserLngLat (state, param) {
      state.lnglat =
        param && param.longitude && param.latitude
          ? [param.longitude, param.latitude]
          : [105.829757, 32.433668]
    },
    clear_user (state, param) {
      state.ip = ''
      state.port = ''
      state.pwd = ''
      state.userName = ''
      state.userId = ''
      state.lnglat = []
      state.hdVideo = null
    }
  },
  actions: {
    addUserInfo ({ commit }, param) {
      commit('addUserInfo', param)
    },
    addUserLngLat ({ commit }, param) {
      commit('addUserLngLat', param)
    },
    clear_user ({ commit }, param) {
      commit('clear_user', param)
    }
  }
}
export default addUserInfo
