<template>
  <div>
    <el-dialog
      title="报警详情"
      custom-class="alarmDialog"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="70%"
      top="10vh"
      @close="clearGlobalAlarm"
    >
      <div>
        <alarm-dia
          class="body"
          :isGlobalAlarm="true"
          :alarmID="alarmID"
          :alarmDetail="alarmDetail"
          @cancelDialog="cancelDialog"
          ref="alarmDia"
        ></alarm-dia>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="clearGlobalAlarm"
          >忽略全部</el-button
        >
        <el-badge
          v-if="isNextAlarmVisible"
          :value="$store.state.vehicle.globalAlarmArr.length - 1"
          :max="200"
          class="item"
        >
          <el-button type="primary" size="small" @click="onNextAlarm"
            >下一条</el-button
          >
        </el-badge>
        <el-button type="primary" size="small" @click="onSaveDetail"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 提示音 -->
    <iframe :src="audioName" frameborder="0" allow="autoplay"></iframe>
    <!-- 车辆上线提示音 -->
    <iframe :src="onlineAudio" frameborder="0" allow="autoplay"></iframe>
    <!-- 车辆下线提示音 -->
    <iframe :src="outlineAudio" frameborder="0" allow="autoplay"></iframe>
  </div>
</template>
<script>
import { RefreshTypeEnum } from '@/assets/js/Tools.js'
import { yh_sdp } from '@/assets/js/yh_sdp.js'
import baseConfig from '@/api/static/config'
import Vue from 'vue'
import NProgress from 'nprogress'
import TripleDES from '@/api/lib/crypto.js'
import alarmDia from '@/views/monitor/alarmDeal/components/alarmDia.vue'
import { Notification } from 'element-ui'

import { queryExcelExportList } from '@/api/lib/refreshAlarm.js'
let Base64 = require('js-base64').Base64

export default {
  components: {
    alarmDia
  },
  data () {
    return {
      basePath: baseConfig.websocketBaseUrl,
      WebSocket: null,
      dialogVisible: false, // 显示报警处理详情
      alarmID: null, // 报警处理id
      alarmDetail: {
        alarmSignTime: '',
        terminalPhoneNo: '',
        disposeStatus: null
      },
      audioType: [
        { id: 'audio1', src: '/audio/alarmVoice(1).mp3' },
        { id: 'audio2', src: '/audio/alarmVoice(2).mp3' },
        { id: 'audio3', src: '/audio/alarmVoice(3).mp3' },
        { id: 'audio4', src: '/audio/alarmVoice(4).mp3' },
        { id: 'audio5', src: '/audio/alarmVoice(5).mp3' }
      ], // 声音列表
      downloadList: this.$store.state.vehicle.downloadList, // 历史视频进程
      circleDownLoadTime: 20 * 1000, // 20秒获取一次数据,更新状态
      closeDownLoadStatus: false, // 最小化历史视频进程框
      isNextAlarmVisible: true,
      audioName: '',
      onlineAudio: '',
      outlineAudio: '',
      reUploadStatus: false,
      showOffInfo: false,
      notify: null,
      remindNotify: null,
      timeOutTerminal: null
    }
  },
  watch: {
    '$store.state.vehicle.globalAlarmArr' (val) {
      this.isNextAlarmVisible = val.length > 1
    }
  },
  computed: {},
  created () {
    this.init()
  },
  mounted () { },
  methods: {
    init () {
      NProgress.start()
      /* 获取车组车辆信息  创建 websocket链接 */
      // if (this.$yhsdp && this.$yhsdp.bIsPassLogin) return
      this.createYhsdp()
      // 页面关闭时，去断开websocket链接
      window.onbeforeunload = function () {
        if (this.$yhsdp && this.$yhsdp.thisReceive) {
          this.$yhsdp.thisReceive.CloseConnectServer()
        }
      }
      // this.screenWidth = window.screen.width;//屏幕分辨率的宽
      this.screenWidth = document.body.clientWidth // 网页可见区域宽(分辨率?宽度)
      this.screenWidthChange()
      let self = this
      window.onresize = () => {
        self.screenWidthChange()
      }
    },
    /** 页面改变宽度 */
    screenWidthChange () {
      let screenCoefficient = 0
      if (this.screenWidth >= 1920) {
        screenCoefficient = 1.3
      }
      if (this.screenWidth < 1920 && this.screenWidth >= 1600) {
        screenCoefficient = 1.15
      }
      if (this.screenWidth < 1600) {
        screenCoefficient = 1
      }
      this.$store.dispatch('set_screenCoefficient', screenCoefficient)
    },
    /* 创建websocket */
    createYhsdp () {
      let self = this
      let userInfo = this.$store.state.user
      Vue.prototype.$RefreshTypeEnum = RefreshTypeEnum

      /** 建立连接websocket对象参数 */
      Vue.prototype.$yhsdp = new yh_sdp(
        self.basePath,
        userInfo.userId,
        userInfo.userName,
        userInfo.ip,
        self.getSuccess
      )
      /** 在此登录远程websocket */
      this.$yhsdp.ConnectSocketServer()
    },
    getSuccess (sText, iStatus) {
      // /999表示websocket连接关闭,888表示websocket连接报错
      // 连接成功
      // console.log(sText, iStatus)
      if (iStatus === 2) {
        NProgress.done()
        // 车辆上下线数据回来
        this.$yhsdp.AddRefreshCallBack(
          this.$RefreshTypeEnum.GPS_Vehicle,
          this.getGpsRealInfo
        )
        // 车辆报警数据
        this.$yhsdp.AddRefreshCallBack(
          this.$RefreshTypeEnum.GPS_Alarm,
          this.getGpsAlarmSuccess
        )
        // 指令信息
        this.$yhsdp.AddRefreshCallBack(
          this.$RefreshTypeEnum.GPS_InteractionDataOut,
          this.getInteractionDataOut
        )
        // 历史视频上传
        this.$yhsdp.AddRefreshCallBack(
          this.$RefreshTypeEnum.GPS_DownLoadHistoryVideo,
          this.getDownLoadList
        )

        /**查询终端属性 1-100条 */
        this.$yhsdp.AddRefreshCallBack(
          this.$RefreshTypeEnum.GB_QueryTerminalProperties,
          this.getTerminalProperties
        )
        this.$yhsdp.AddRefreshCallBack(
          this.$RefreshTypeEnum.GB_TerminalInterval,
          this.getTerminalInterval
        )

        /**提醒用户系统已更新 */
        this.$yhsdp.AddRefreshCallBack(
          this.$RefreshTypeEnum.GB_Remind,
          this.remindUpdate
        )

        if (this.$route.path.indexOf('login') > -1) return
        /** 定时获取下载列表的状态 */
        this.getDownloadList()
        /** 定时获取指令下发列表的超时与否状态 */
        this.getInstructionsListStatus()
      } else if (iStatus === 888 || iStatus === 999) {
        if (this.$route.path.indexOf('login') > -1) return
        if (this.showOffInfo) return
        this.showOffInfo = true
        this.notify = this.$notify({
          title: '连接断开',
          message: 'websocket连接断开，请点击重新连接',
          type: 'warning',
          duration: 0,
          onClick: this.onClickNotify,
          onClose: this.onCloseNotify
        })
      }
    },
    onClickNotify () {
      this.notify.close()
      this.showOffInfo = false
      this.$yhsdp.ConnectSocketServer(true)
    },
    onCloseNotify () {
      this.showOffInfo = false
      this.$yhsdp.ConnectSocketServer(true)
    },
    /** 指令信息 */
    getInteractionDataOut (data) {
      switch (data.type) {
        case 0:
          this.$store.commit('add_interactiveArr', {
            status: 0,
            ...data.param,
            sNote: ''
          })
          break
        case 1:
          this.$store.commit('change_interactiveArr', data.param)
          break

        default:
          break
      }
    },

    /** 下发指令——历史视频上传 */
    getDownLoadList () {
      this.$message.success('导出文件后台生成中，完成后请到下载中心下载文件。')
    },

    /**查询终端属性 1-100条 */
    getTerminalProperties (data) {
      let index = this.$store.state.vehicle.terminalObj.list.findIndex(
        (res) => data.device == res
      )
      let time =
        new Date().getTime() -
        this.$store.state.vehicle.terminalObj.time.getTime() //毫秒
      if (index > -1 && time <= 15000) {
        let vehicleInfos = this.$store.state.vehicle.terminalObj.vehicleInfos[
          index
        ]

        /**不超过等待时间 */
        this.$store.dispatch('add_terminalPropertiesList', {
          ...data.result,
          device: data.device,
          vehicleNo: vehicleInfos.split('#')[0],
          vehicleInfos: vehicleInfos
        })
      }
    },
    /**查询终端属性 1-100条 */
    getTerminalInterval () {
      let time = new Date()
      let timeOut = null

      timeOut = setTimeout(() => {
        /***等待到15秒 */
        if (this.$store.state.vehicle.terminalPropertiesList.length == 0) {
          this.$message.warning(`网络因素，没有数据返回`)
          this.closeTerminalProperties()
          return
        }
      }, 15000)
      this.timeOutTerminal = timeOut
      this.$once('hook:beforeDestroy', () => {
        clearTimeout(timeOut)
      })
    },
    /**清除查询终端属性store */
    closeTerminalProperties () {
      clearTimeout(this.timeOutTerminal)
      this.$store.dispatch('clear_terminalPropertiesList') //清空数据
      this.$store.dispatch('set_terminalObj', null) //清空查询数据
    },

    /* 定时获取指令下发列表的超时与否状态 15秒 */
    getInstructionsListStatus () {
      if (this.$route.path.indexOf('login') > -1) return
      const timer = setInterval(() => {
        this.$store.state.vehicle.interactiveArr.forEach((item) => {
          let timeDiff =
            (new Date().valueOf() - new Date(item.sTime).valueOf()) / 1000
          if (item.status === 0 && timeDiff > 15) {
            this.$store.commit('change_interactiveArr', {
              ...item,
              sTime: '/',
              sNote: '超时'
            })
          }
        })
      }, 15 * 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    },

    /* 全局弹窗(关闭弹窗) */
    closedia (type, nodeal) {
      this.dialogVisible = false
      if (type === 1) {
        /* 全局弹窗 点击下一条 */
        this.closeGlobalAlarm()
      } else {
        /* 全局弹窗 点击处理或忽略全部 */
        this.$store.dispatch('clearGlobalAlarm')
      }
    },
    /* 处理一条报警数据 */
    closeGlobalAlarm () {
      this.$store.dispatch('cutGlobalAlarm')
      if (this.$store.state.vehicle.globalAlarmArr.length > 0) {
        this.dialogVisible = true
      }
    },
    /** 车辆上下线提醒 */
    getGpsRealInfo (data) {
      let onlineRemind = JSON.parse(localStorage.getItem('onlineRemind'))
      let outlineRemind = JSON.parse(localStorage.getItem('outlineRemind'))
      if (data.status === 0 && outlineRemind) {
        this.$notify({
          title: '车辆下线提醒',
          message: `车辆${data.cph}下线`
        })
        this.playoutlineAudio()
      } else if (data.status === 6 && onlineRemind) {
        this.$notify({
          title: '车辆上线提醒',
          message: `车辆${data.cph}上线`,
          type: 'success'
        })
        this.playonlineAudio()
      }
    },

    /** 车辆报警提醒 */
    getGpsAlarmSuccess (sAlarm) {
      let showAlarmVoice = eval(localStorage.getItem('showAlarmVoice'))
      let showAlarm = eval(localStorage.getItem('showAlarm'))
      let alarm = JSON.parse(sAlarm).data
      this.alarmID = [alarm.alarmNo]
      if (alarm.voice === '1' && showAlarmVoice) {
        // 提醒声音
        this.playAudio(1)
      }
      // 全局弹框
      if (alarm.popup === '1' && showAlarm) {
        this.$store.commit('add_globalAlarm', this.alarmID)
        this.alarmDetail.disposeStatus = alarm.disposeStatus
        this.alarmDetail.terminalPhoneNo = alarm.terminalPhoneNo
        this.alarmDetail.alarmSignTime = alarm.alarmSignTime
        this.dialogVisible = true
      }
    },
    // 提交报警详情
    onSaveDetail () {
      this.$refs.alarmDia.save()
    },
    // 关闭dialog
    cancelDialog (res) {
      this.clearGlobalAlarm()
      do {
        if (!res) break
        if (res.length === 1) {
          this.$message.success(res[0])
          break
        }
        res.forEach((item, index) => {
          if (index === 0) {
            this.$message.warning(item)
          } else {
            setTimeout(() => {
              this.$message.error(item)
            }, 200 * index)
          }
        })
      } while (false)
    },
    /** 关闭报警弹框 */
    closeVdia () {
      this.voiceDialog = false
    },

    /* 播放上线声音 */
    playonlineAudio () {
      try {
        this.onlineAudio = '/audio/carOnline.mp3'
        setTimeout(() => {
          this.onlineAudio = ''
        }, 2000)
      } catch (error) { }
    },
    /* 播放车辆下线声音 */
    playoutlineAudio () {
      try {
        this.outlineAudio = '/audio/outlineAudio.mp3'
        setTimeout(() => {
          this.onlineAudio = ''
        }, 2000)
      } catch (e) { }
    },
    /* 报警提示声音 */
    playAudio (type) {
      for (const item of this.audioType) {
        if (item.id === `audio${type}`) {
          this.audioName = item.src
          setTimeout(() => {
            this.audioName = ''
          }, 2000)
        }
      }
    },
    // 查看下一条报警
    onNextAlarm () {
      this.$refs.alarmDia.showNextAlarm()
    },
    /** 取消显示报警按钮   去除当前报警信息 */
    clearGlobalAlarm () {
      this.dialogVisible = false
      this.$store.commit('clearGlobalAlarm')
    },
    /** 获取未下载(1.等待生成中，2.生成中)列表 */
    getDownloadList () {
      let data = {
        currentPage: 1,
        pageSize: 9999,
        fileStatusList: [1, 2]
      }
      let self = this
      if (this.$route.path.indexOf('login') > -1) return
      var circleTime = this.circleDownLoadTime // 轮询时间间隔(ms)
      let query_fn = function () {
        queryExcelExportList(data).then((res) => {
          /** 状态说明：文件状态（1.等待生成中，2.生成中，3.上传完成，4.上传失败，5.已删除 */
          if (res.code === 1000) {
            self.$store.commit('set_downLoadListLength', res.data.total)
          }
        })
      }
      query_fn()
      const timer = setInterval(() => {
        query_fn()
      }, circleTime)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    },
    //提醒用户系统已更新
    remindUpdate (res) {
      let message = res.message.replace(/\s+/g, '+');
      let decodeMessage = Base64.decode(message)
      this.remindNotify = this.$notify({
        title: '提示',
        message: decodeMessage,
        type: 'success',
        duration: 0,
        onClick: this.onClickRemindNotify,
        onClose: this.onCloseRemindNotify
      })
    },
    onClickRemindNotify () {
      this.remindNotify.close()
      location.reload()
    },
    onCloseRemindNotify () {
      location.reload()

    },
  },
  /** 页面销毁 清除监听 */
  beforeDestroy () {
    clearInterval(this.timerAlarm)
    if (this.notify) this.notify.close()
    if (this.remindNotify) this.remindNotify.close()
    this.showOffInfo = false
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GPS_Vehicle,
      this.getGpsRealInfo
    )
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GPS_Alarm,
      this.getGpsAlarmSuccess
    )
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GPS_InteractionDataOut,
      this.getInteractionDataOut
    )
    // 历史视频上传
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GPS_DownLoadHistoryVideo,
      this.getDownLoadList
    )
    /**查询终端属性 1-100条 */
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GB_QueryTerminalProperties,
      this.getTerminalProperties
    )
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GB_TerminalInterval,
      this.getTerminalInterval
    )
    /**提醒用户系统已更新 */
    this.$yhsdp.RemoveRefreshCallBack(
      this.$RefreshTypeEnum.GB_Remind,
      this.remindUpdate
    )
  }
}
</script>
<style lang="scss" scoped>
.el-badge {
  margin: 0 10px;
}
.downLoadNarrowBox {
  z-index: 20;
  width: 35vh;
  padding: 0 20px;
  height: 50px;
  position: fixed;
  right: 10px;
  bottom: 20px;
  line-height: 50px;
  text-align: center;
  color: #afb4ce !important;
  background-color: #31353e;
}
.downLoadBox {
  z-index: 2000;
  width: 520px;
  height: 240px;
  position: fixed;
  right: 10px;
  bottom: 20px;
  box-sizing: border-box;
  color: #666 !important;
  background-color: white;
  border: 1px solid white;
  -box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;
  overflow: hidden;
  .downLoadBoxTitle {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 21;
    .title {
      display: flex;
      justify-content: space-around;
      padding: 8px;
      text-align: center;
      align-items: center;
      p {
        flex: 8;
        font-size: 1.8vh;
      }
      span {
        flex: 1;
        width: 20px;
        border: 1px solid #666;
      }
    }
  }
}
</style>
