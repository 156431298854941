import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
// import Layout from '@/views/layout/Home'
import EmptyLayout from '@/views/layout/EmptyLayout'
Vue.use(Router)

/**
 * 当点击本页面链接的导航，当前位置的冗余导航报错
 */
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  routes: [{
      path: '/',
      redirect: '/platform',
    },
    {
      path: '/login',
      component: resolve => require(['@/views/login/login.vue'], resolve),
      meta: {
        title: '登录'
      }
    },

    {
      path: '/404',
      component: resolve => require(['@/components/common/404.vue'], resolve),
      meta: {
        title: '404'
      }
    },
    {
      path: '/500',
      component: resolve => require(['@/components/common/500.vue'], resolve),
      meta: {
        title: '500'
      }
    },
    {
      path: '/layout',
      component: EmptyLayout,
      redirect: 'noRedirect',
      children: [{
          path: '/platform',
          component: () => import('@/views/login/platform.vue'),
          meta: {
            title: '平台'
          }
        },
        /** 出租路由 */
        {
          path: '',
          component: resolve =>
            require(['@/views/layout/Home.vue'], resolve),
          meta: {
            title: ''
          },
          children: [{
              path: '/projectTaxi',
              redirect: '/projectTaxi/dashboard'
            },
            {
              path: '/projectTaxi/dashboard',
              component: resolve =>
                require(['@/views/projectTaxi/dashboard/dashboard.vue'], resolve),
              meta: {
                title: '数据看板'
              }
            },
          ]
        },
        /** 公交路由 */
        {
          path: '/projectBus',
          name: '',
          component: resolve =>
            require(['@/views/layout/Home.vue'], resolve),
          meta: {
            title: ''
          },
          children: [{
              path: '/projectBus',
              redirect: '/projectBus/dashboard'
            },
            {
              path: '/projectBus/dashboard',
              component: resolve =>
                require(['@/views/projectBus/dashboard/dashboard.vue'], resolve),
              meta: {
                title: '数据看板'
              }
            }
          ]
        },
        /** gps路由 */
        {
          path: '/projectGps',
          name: '',
          component: resolve =>
            require(['@/views/layout/Home.vue'], resolve),
          meta: {
            title: ''
          },
          children: [{
              path: '/projectGps',
              redirect: '/projectGps/dashboard'
            },
            {
              path: '/projectGps/dashboard',
              component: resolve =>
                require(['@/views/projectGps/dashboard/dashboard.vue'], resolve),
              meta: {
                title: '数据看板'
              }
            },
            {
              path: '/projectGps/monitoring',
              component: resolve =>
                require(['@/views/monitor/monitoring/monitoring.vue'], resolve),
              meta: {
                title: '运营监控'
              }
            },
            {
              path: '/projectGps/alarmManagement',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '报警管理'
              },
              children: [{
                  path: '/projectGps/alarmDeal',
                  component: resolve =>
                    require([
                      '@/views/monitor/alarmDeal/alarmDeal.vue'
                    ], resolve),
                  meta: {
                    title: '报警处理'
                  }
                },
                {
                  path: '/projectGps/alarmSetting',
                  component: resolve =>
                    require([
                      '@/views/monitor/alarmSetting/alarmSetting.vue'
                    ], resolve),
                  meta: {
                    title: '报警配置'
                  }
                }
              ]
            },
            {
              path: '/projectGps/positionManage',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '位置管理'
              },
              children: [
                /** 4-1 位置管理positionManage_区域围栏管理 */
                {
                  path: '/projectGps/enclosureDefin',
                  component: resolve =>
                    require([
                      '@/views/projectGps/positionManage/enclosureDefin/enclosureDefin.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '区域围栏管理'
                  }
                },

                /** 4-2 位置管理_风险源管理 */
                {
                  path: '/projectGps/dangerSource',
                  component: resolve =>
                    require([
                      '@/views/projectGps/positionManage/dangerSource/dangerSource.vue'
                    ], resolve),
                  meta: {
                    title: '风险源管理'
                  }
                },
                /** 4-3 位置管理_车辆绑定区域查询 */
                {
                  path: '/projectGps/vehicleBinding',
                  component: resolve =>
                    require([
                      '@/views/projectGps/positionManage/vehicleBinding/vehicleBinding.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '车辆绑定区域查询'
                  }
                }
              ]
            },
            {
              path: '/projectGps/operation',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '运营管理'
              },
              children: [
                /** 5-1 运营管理_旅游客车行程 */
                {
                  path: '/projectGps/tourismStatistics',
                  name: 'tourismStatistics',
                  component: resolve =>
                    require([
                      '@/views/projectGps/operationManagement/tourismStatistics/tourismStatistics.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '旅游客车行程'
                  }
                },
                /** 5-2 运营管理_五不出站管理 */
                {
                  path: '/projectGps/serchFivePoint',
                  name: 'serchFivePoint',
                  component: resolve =>
                    require([
                      '@/views/projectGps/operationManagement/serchFivePoint/serchFivePoint.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '五不出站管理'
                  }
                },
                /** 5-3 运营管理_危险品运输管理 */
                {
                  path: '/projectGps/dangerousGoodsManage',
                  name: 'dangerousGoodsManage',
                  component: resolve =>
                    require([
                      '@/views/projectGps/operationManagement/dangerousGoodsManage/dangerousGoodsManage.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '危险品运输管理'
                  }
                }
              ]
            },
            {
              path: '/projectGps/business',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '行驶统计'
              },
              children: [
                /** 6-1 行驶统计-里程、轨迹统计 */
                {
                  path: '/projectGps/mileageStatistics',
                  name: 'mileageStatistics',
                  component: resolve =>
                    require([
                      '@/views/projectGps/drivingStatistics/mileageStatistics/mileageStatistics.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '里程轨迹统计'
                  }
                },
                /** 6-2 行驶统计-车辆在线率 */
                {
                  path: '/projectGps/carOnlineProbability',
                  component: resolve =>
                    require([
                      '@/views/projectGps/drivingStatistics/carOnlineProbability/carOnlineProbability.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '车辆在线率统计'
                  }
                },
                /** 6-3 行驶统计-离线车辆统计 */
                {
                  path: '/projectGps/outlineStatistics',
                  component: resolve =>
                    require([
                      '@/views/projectGps/drivingStatistics/outlineStatistics/outlineStatistics.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '离线车辆统计'
                  }
                },
                /** 6-4 行驶统计-定位统计 */
                {
                  path: '/projectGps/locationStatistics',
                  component: resolve =>
                    require([
                      '@/views/projectGps/drivingStatistics/locationStatistics/locationStatistics.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '定位统计'
                  }
                },
                /** 6-5 行驶统计-登签退签统计 */
                {
                  path: '/projectGps/signUp',
                  component: resolve =>
                    require([
                      '@/views/projectGps/drivingStatistics/signUp/signUp.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '登签退签统计'
                  }
                },
                /** 6-6 行驶统计-设备故障统计 */
                {
                  path: '/projectGps/equipmentStatistics',
                  component: resolve =>
                    require([
                      '@/views/projectGps/drivingStatistics/equipmentStatistics/equipmentStatistics.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '设备故障统计'
                  }
                }
              ]
            },
            {
              path: '/projectGps/alertReport',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '报警统计'
              },
              children: [
                /** 7-1 报警统计_超速报警统计 */
                {
                  path: '/projectGps/overSpeed',
                  component: resolve =>
                    require([
                      '@/views/projectGps/alertReport/overSpeed/overSpeed.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '超速报警统计'
                  }
                },
                /** 7-2 报警统计_疲劳驾驶 */
                {
                  path: '/projectGps/fatigueDriving',
                  component: resolve =>
                    require([
                      '@/views/projectGps/alertReport/fatigueDriving/fatigueDriving.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '疲劳驾驶统计'
                  }
                },
                /** 7-3 报警统计_车辆报警分析*/
                {
                  path: '/projectGps/vechileAlarm',
                  component: resolve =>
                    require([
                      '@/views/projectGps/alertReport/vechileAlarm/vechileAlarm.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '车辆报警分析'
                  }
                },
                /** 7-4 报警统计_驾驶员行为分析 */
                {
                  path: '/projectGps/driverScore',
                  component: resolve =>
                    require([
                      '@/views/projectGps/alertReport/driverScore/driverScore.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '驾驶员行为分析'
                  }
                },
                /** 7-5 报警统计_报警次数统计 */
                {
                  path: '/projectGps/alarmsNumber',
                  component: resolve =>
                    require([
                      '@/views/projectGps/alertReport/alarmsNumber/alarmsNumber.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '报警次数统计'
                  }
                }
                
              ]
            },
            {
              path: '/projectGps/otherReport',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '管理行为分析'
              },
              children: [
                /** 8-10 管理行为分析_抽查车辆 */
                {
                  path: '/projectGps/checkVehicle',
                  component: resolve =>
                    require([
                      '@/views/projectGps/otherReport/checkVehicle/checkVehicle.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '车辆抽查统计'
                  }
                },
                /** 8-11 管理行为分析_调度统计 */
                {
                  path: '/projectGps/dispatchStatistics',
                  component: resolve =>
                    require([
                      '@/views/projectGps/otherReport/dispatchStatistics/dispatchStatistics.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '车辆调度统计'
                  }
                },
                /** 8-12 管理行为分析_用户登录统计 */
                {
                  path: '/projectGps/userLoginStatis',
                  component: resolve =>
                    require([
                      '@/views/projectGps/otherReport/userLoginStatis/userLoginStatis.vue'
                    ], resolve),
                  meta: {
                    keepAlive: true,
                    title: '用户登录统计'
                  }
                },

              ]
            }
          ],
        },
        /** 公共资源 */
        {
          path: '',
          component: resolve =>
            require(['@/views/layout/Home.vue'], resolve),
          meta: {
            title: ''
          },
          children: [{
              path: '/projectResourse',
              redirect: '/projectResourse/basicData'
            },
            {
              path: '/projectResourse/basicData',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '基础信息管理'
              },
              children: [{
                  path: '/projectResourse/vehicleManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/basicData/vehicleManagement/vehicleManagement.vue'
                    ], resolve),
                  meta: {
                    title: '车辆管理'
                  }
                },
                {
                  path: '/projectResourse/driverManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/basicData/driverManagement/driverManagement.vue'
                    ], resolve),
                  meta: {
                    title: '驾驶员管理'
                  }
                },
                {
                  path: '/projectResourse/terminalManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/basicData/terminalManagement/terminalManagement.vue'
                    ], resolve),
                  meta: {
                    title: '终端管理'
                  }
                },
                {
                  path: '/projectResourse/ICCardManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/basicData/ICCardManagement/ICCardManagement.vue'
                    ], resolve),
                  meta: {
                    title: 'IC卡管理'
                  }
                },
                {
                  path: '/projectResourse/yardManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/basicData/yardMange/yardMange.vue'
                    ], resolve),
                  meta: {
                    title: '车场管理'
                  }
                },
                {
                  path: '/projectResourse/siteManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/basicData/siteMange/siteMange.vue'
                    ], resolve),
                  meta: {
                    title: '站点管理'
                  }
                }
              ]
            },
            {
              path: '/projectResourse/SystemSetting',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '系统设置'
              },
              children: [{
                  path: '/projectResourse/businessManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/businessManagement/businessManagement.vue'
                    ], resolve),
                  meta: {
                    title: '企业管理'
                  }
                },
                {
                  path: '/projectResourse/userManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/userManagement/userManagement.vue'
                    ], resolve),
                  meta: {
                    title: '用户管理'
                  }
                },
                {
                  path: '/projectResourse/userGroupManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/userGroupManagement/userGroupManagement.vue'
                    ], resolve),
                  meta: {
                    title: '用户组管理'
                  }
                },
                {
                  path: '/projectResourse/roleManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/roleManagement/roleManagement.vue'
                    ], resolve),
                  meta: {
                    title: '角色管理'
                  }
                },
                {
                  path: '/projectResourse/authorizationManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/authorizationManagement/authorizationManagement.vue'
                    ], resolve),
                  meta: {
                    title: '权限管理'
                  }
                },
                {
                  path: '/projectResourse/menuManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/menuManagement/menuManagement.vue'
                    ], resolve),
                  meta: {
                    title: '菜单管理'
                  }
                },
                {
                  path: '/projectResourse/dataDictionary',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/dataDictionary/dataDictionary.vue'
                    ], resolve),
                  meta: {
                    title: '数据字典'
                  }
                },
                {
                  path: '/projectResourse/equipmentModel',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/equipmentModel/equipmentModel.vue'
                    ], resolve),
                  meta: {
                    title: '设备型号管理'
                  }
                },
                {
                  path: '/projectResourse/accessManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/accessManagement/accessManagement.vue'
                    ], resolve),
                  meta: {
                    title: '准入管理'
                  }
                },
                {
                  path: '/projectResourse/upgradeManagement',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/systemSettings/upgradeManagement/upgradeManagement.vue'
                    ], resolve),
                  meta: {
                    title: '升级包管理'
                  }
                }
              ]
            },
            {
              path: '/projectResourse/log',
              component: resolve =>
                require(['@/components/basicDataMange/index.vue'], resolve),
              meta: {
                title: '日志管理'
              },
              children: [{
                  path: '/projectResourse/logIn',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/logManagement/logIn/logIn.vue'
                    ], resolve),
                  meta: {
                    title: '登录日志'
                  }
                },
                {
                  path: '/projectResourse/operationLog',
                  component: resolve =>
                    require([
                      '@/views/projectResourse/logManagement/operationLog/operationLog.vue'
                    ], resolve),
                  meta: {
                    title: '操作日志'
                  }
                }
              ]
            },
            {
              path: '/projectResourse/mocha',
              component: resolve =>
                require(['@/views/projectResourse/mocha/mocha.vue'], resolve),
              meta: {
                title: '运维管理'
              },
            }

          ]
        }
      ]
    },
  ]
})

const addStorageEvent = function (k, val) {
  const newStorageEvent = document.createEvent('StorageEvent')
  localStorage.setItem(k, val)
  // 初始化创建的事件
  newStorageEvent.initStorageEvent(
    'setItem',
    false,
    false,
    k,
    null,
    val,
    null,
    null
  )
  // 派发对象
  window.dispatchEvent(newStorageEvent)
}

// 全局路由守卫
NProgress.configure({
  showSpinner: false
})
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.path == '/login') {
    next();
  } else {
    var token = sessionStorage.getItem('token');
    //如果没登录,都导向登录页
    if (!token) {
      if (to.path !== '/login') {
        next({
          path: '/login'
        })
      } else {
        next();
      }
    } else {
      next();
    }
  }
})
router.afterEach(to => {
  if (to.path.indexOf('projectGps') > -1) {
    addStorageEvent('YH_BF_THEME', 'theme-project-gps')
    sessionStorage.setItem("system", 0)
  }
  if (to.path.indexOf('projectTaxi') > -1) {
    addStorageEvent('YH_BF_THEME', 'theme-project-taxi')
    sessionStorage.setItem("system", 1)
  }
  if (to.path.indexOf('projectBus') > -1) {
    addStorageEvent('YH_BF_THEME', 'theme-project-bus')
    sessionStorage.setItem("system", 2)
  }
  if (to.path.indexOf('projectResourse') > -1) {
    addStorageEvent('YH_BF_THEME', 'theme-project-resourse')
  }
  if (to.path) {
    window.sessionStorage.setItem('routeName', to.path.substr(1))
  }
  NProgress.done()
})
export default router
