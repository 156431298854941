import CryptoJS from 'crypto-js'

class tripleDES {
  decode (text) {
    var str_key = CryptoJS.enc.Utf8.parse('yinghairuitekeji')
    var decrypt = CryptoJS.AES.decrypt(text, str_key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    var data = CryptoJS.enc.Utf8.stringify(decrypt)
    return data
  }

  encode (text) {
    var str_key = CryptoJS.enc.Utf8.parse('yinghairuitekeji')
    var srcs = CryptoJS.enc.Utf8.parse(`${text}`)
    var encrypttext = CryptoJS.AES.encrypt(srcs, str_key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return encrypttext.toString()
  }
}

export default new tripleDES()
