/*
create by gw : 2018-10-18  用于实现与gps中心转发服务器的数据交互

*/

import {
  yh_socket
} from './yh_socket.js'

import {
  YHTools,
  opertionType,
  alarmShield
} from './Tools.js'


/**
 * purpose   接收Gps服务器数据
 * sRemoteIp  远端ip
 * sUserid     用户id* callObject
 * callBack_DataReturn 数据返回回调   参数(int iPacketID, string sipAddress, VehTempData, thisVehicle, byte[] PacketData, int iVehCount)
 * callBack_InteractionData 交互信息回调  参数(string sVehicleNo, string sTime, string sCmdTxt, string sNote)
 * callBack_ReturnCnnText 连接状态信息回调  参数(string sReturnTex, int CnnStatus)  //CnnStatus 0数据失败 1正在交互数据 2交互数据完成
 */
export class GpsReceiveData {
  /**
   *
   * @param {string} sRemoteIp 连接websocket 地址ip
   * @param {string} sUserid 账号id
   * @param {string} sUserName 账号名称
   * @param {string} sLoginIp 账号登录ip 唯一识别客户端
   * @param {object} callObject
   * @param {Function} callBack_DataReturn data return call back function
   * @param {Function} callBack_InteractionData
   * @param {Function} callBack_ReturnCnnText
   * @param {Function} callBack_returnDownLoadList //历史视频上传列表
   *
   **/
  constructor(sRemoteIp, sUserid, sUserName, sLoginIp, callObject, callBack_DataReturn, callBack_InteractionData, callBack_ReturnCnnText, callBack_returnDownLoadList) {
    this.DataReturnCallBack = callBack_DataReturn
    this.InteractionDataCallBack = callBack_InteractionData
    this.ReturnCnnTextCallBack = callBack_ReturnCnnText
    this.returnDownLoadListCallBack = callBack_returnDownLoadList
    this.mCallObject = callObject

    this.TcpSocket = null
    this.RemoteIp = '' // 远程ip地址
    this.Userid = '' // 用户id
    this.LoginIp = '' // 账号登录ip 唯一识别客户端
    this.UserName = '' // 当前操作用户名
    this.websocketOnError = 0 //  websocket 错误重连次数

    this.thisLastTime = null // 接收数据的最后时间
    this.thisLastSendTime = null // 发送数据的最后时间
    this.MaxLogoutTime = new Date('1970-01-01 00:00:00') // 登陆时间

    this.bLoggedin = false // 是否已登录

    this.orderVehcileList = new Map() // 下发指令的数据缓存   key：数字(orderId)  value：终端号_车牌号_公司名称

    this.orderId = 0 // 下发指令每条数据的id，为了返回数据定位
    this.bExitthrd = false // 是否退出接收线程
    this.bAutoCnn = false // 是否可以自动连接到服务器

    this.Create(sRemoteIp, sUserid, sUserName, sLoginIp)
  }

  /* -------------------------------------------------------------------------------回调函数-------------------------------------------------------------- */

  /**
   * 交互信息回调(下发指令,指令返回信息)
   * @param {object} callObject this
   * @param {string} sVehicleNo 车辆号码
   * @param {string} vehGroupName 车辆所属公司
   * @param {string} sTime 操作时间
   * @param {string} sCmdTxt 命令
   * @param {string} orderId 指令id
   * @param {string} terminalNo 终端号
   */

  InteractionDataOut(callObject, sVehicleNo, vehGroupName, sTime, sCmdTxt, orderId, terminalNo) {
    if (callObject.InteractionDataCallBack != null) {
      if (callObject.mCallObject != null) {
        callObject.InteractionDataCallBack(callObject.mCallObject, sVehicleNo, vehGroupName, sTime, sCmdTxt, orderId, terminalNo)
      } else {
        callObject.InteractionDataCallBack(sVehicleNo, sTime, sCmdTxt)
      }
    }
  }

  /**
   * 历史视频上传 列表
   * @param {*} callObject
   * @param {*} data
   * @param {*} terminalNo
   */

  returnDownLoadList(callObject) {
    if (callObject.returnDownLoadListCallBack != null) {
      if (callObject.mCallObject != null) {
        callObject.returnDownLoadListCallBack(callObject.mCallObject)
      } else {
        callObject.returnDownLoadListCallBack()
      }
    }
  }

  // 数据返回
  ReturnCmdData(callObject, dataType, btPacketData) {
    if (callObject.DataReturnCallBack != null) {
      if (callObject.mCallObject != null) {
        callObject.DataReturnCallBack(callObject.mCallObject, dataType, btPacketData)
      } else {
        callObject.DataReturnCallBack(dataType, btPacketData)
      }
    }
  }

  // 连接状态信息nCnnStatus 0数据失败 1正在交互数据 2交互数据完成
  thisReturnCnnText(callObject, sReturnText, nCnnStatus) {
    if (callObject.ReturnCnnTextCallBack != null) {
      if (callObject.mCallObject != null) {
        callObject.ReturnCnnTextCallBack(callObject.mCallObject, sReturnText, nCnnStatus)
      } else {
        callObject.ReturnCnnTextCallBack(sReturnText, nCnnStatus)
      }
    }
  }
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /* -------------------------------------------------------------------------------操作指令相关函数-------------------------------------------------------------- */

  /// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * websocket返回的数据解析
   * 位置数据/报警数据/指令数据做区分
   * @param {Object} callObject 对象本身
   * @param {Object} returnData 返回数据
   */
  AddDataPacket(callObject, returnData) {
    // 这里接受到了数据,callObject
    const responceData = JSON.parse(returnData)
    try {
      switch (responceData.type) {
        case '0xEE':
          // 登陆失败！
          callObject.thisReturnCnnText(callObject, '登陆失败！', 0)
          callObject.bExitthrd = true
          callObject.TcpSocket.closeSocket()
          break
        case '0xE2':
          // 报警信息
          callObject.ReturnCmdData(callObject, responceData.type, returnData)
          break

        case '0xE3': // 车辆拍照返回数据
          callObject.ReturnCmdData(callObject, responceData.type, returnData)
          break
        case '0xE4': // 车辆在线,下线提醒
          callObject.ReturnCmdData(callObject, responceData.type, returnData)
          break
        case '0xE5': // 获取聊天消息数据(获取)
          callObject.ReturnCmdData(callObject, responceData.type, returnData)
          break
        case '0xE6': // 发送聊天消息数据

          break
        case '0xE7': // 下发指令|获取车辆视频相关指令
          callObject.ReturnCmdData(callObject, responceData.type, returnData)
          break

        case '0xE8': // 需要登出
          callObject.ReturnCmdData(callObject, responceData.type, returnData)
          break
        case '0xEC': // 获取上级平台的消息数据(获取)20200109

          callObject.ReturnCmdData(callObject, responceData.type, returnData)

          break
        case '0xEB': // 收到报警督办信息20200109
          callObject.ReturnCmdData(callObject, responceData.type, returnData)

          break

        default:

          break
      }
    } catch (e) {
      // 数据解析  异常
      console.error('GpsReceiveData  AddDataPacket  error: ' + e.message)
    }
  }

  /**
   * Socket连接成功回调 2020-06-19
   * @param {object} callObject  callObject callObject其实就是此类本身，这里再传是因为js回调机制的原因，回调后this就成了window了
   * @param {object} evt evt 返回的数据
   */

  Socket_Open(callObject, evt) {
    callObject.thisReturnCnnText(callObject, '登陆成功！正准备从服务器更新数据。', 2)
    callObject.bLoggedin = true
    callObject.websocketOnError = 0
  }

  /**
   * Socket接收到消息
   * callObject其实就是此类本身，这里再传是因为js回调机制的原因，回调后this就成了window了
   * @param {object} callObject 此类本身
   * @param {object} returnData 返回数据
   */

  Socket_OnMessage(callObject, returnData) {
    // 这里接到了数据,
    callObject.thisLastTime = new Date()
    callObject.websocketOnError = 0

    callObject.AddDataPacket(callObject, returnData)
  }

  /**
   * Socket连接关闭
   * 在非正常断开情况下去重新连接
   * @param {*} callObject
   * @param {*} evt
   */
  Socket_OnClose(callObject, evt) {
    if (callObject.websocketOnError < 10) {
      if (evt.code === 1006 || evt.code === 1001 || !evt.wasClean) {
        callObject.StartConnectServer(callObject)
        callObject.websocketOnError = callObject.websocketOnError + 1
      } else {
        callObject.thisReturnCnnText(callObject, '服务器连接出现问题。', 888)
      }
    } else {
      callObject.thisReturnCnnText(callObject, '服务器连接出现问题。', 888)
    }
  }

  /**
   * Socket通信错误
   * @param {*} callObject
   * @param {*} evt
   */
  Socket_OnError(callObject, evt) {
    callObject.thisReturnCnnText(callObject, '服务器连接关闭。', 999)
  }

  // 发送数据到服务器
  Socket_SendData(byteArray) {
    if (this.bExitthrd) {
      // 退出发送了
      return
    }
    if (!this.TcpSocket.sendData(byteArray)) {
      console.log('网络有问题')
    }
  }

  // Socket心跳包
  Socket_SendHeartBeat(callObject) {
    // if (callObject.TcpSocket == null || !callObject.TcpSocket.Connected) {
    //   // 退出发送了
    //   return
    // }

    // callObject.SendHeartBeat(callObject)

    // setTimeout(function () {
    //   callObject.Socket_SendHeartBeat(callObject)
    // }, 30 * 1000)
  }

  /** 发送心跳包 */
  SendHeartBeat(callObject) {
    try {
      // callObject.TcpSocket.sendData(null, '0xEF')
    } catch (error) {
      console.error('SendHeartBeat : ')
    }
  }

  // 开启连接到中心服务器
  StartConnectServer(callObject) {
    try {
      if (!callObject.TcpSocket || !callObject.TcpSocket.Connected) {
        // 重新建立socket连接
        callObject.TcpSocket = new yh_socket(callObject.RemoteIp, callObject.Userid, callObject.LoginIp, callObject.Socket_Open, callObject.Socket_OnMessage, callObject.Socket_OnClose, callObject.Socket_OnError, callObject)
        setTimeout(function () {
          callObject.Socket_SendHeartBeat(callObject)
        }, 1 * 1000)
      }
    } catch (e) {
      // 如果不是需要退出网络连接，那么在出现异常的时候进行重新连接操作
      console.error('GpsReceiveData  StartConnectServer  error: ' + e.message)
      console.error('tcpsocket : ' + callObject.TcpSocket)
    }
  }

  // 关闭连接到中心服务器的线程
  CloseConnectServer() {
    if (this.TcpSocket != null) {
      this.TcpSocket.closeSocket() // young 20190817修改
      this.TcpSocket = null
    }
    // this.bIsPassLogin = false;
  }

  // socket连接函数
  SocketConnect(bCnnStart, bAutoCnn) {
    try {
      if (bCnnStart) {
        // 连接到服务器
        this.bAutoCnn = bAutoCnn
        this.bExitthrd = false
        this.StartConnectServer(this)
      } else {
        // 断开服务器
        this.bAutoCnn = false
        this.bExitthrd = true
        this.CloseConnectServer()
      }
    } catch (e) {
      // 提示网络出现错误
      console.error('GpsReceiveData  SocketConnect  error: ' + e.message)
    }
  }

  // 创建函数
  Create(sRemoteIp, sUserid, sUserName, sLoginIp) {
    this.RemoteIp = sRemoteIp
    this.Userid = String(sUserid)
    this.UserName = String(sUserName)
    this.LoginIp = sLoginIp

    this.thisLastTime = new Date() // 接收数据的最后时间
    this.thisLastSendTime = new Date() // 发送数据的最后时间
    this.MaxLogoutTime = new Date() // 登陆时间
  }

  /**
   * 组装好数据,通过websocket发送
   * 数据格式: //具体参考石墨文档
   * @param {object} params 数据
   * @param {string} sCmdTxt 命令
   * @param {string} sNote 内容
   */
  SendCmdToCenter(params, sCmdTxt) {
    const data = JSON.parse(params)
    try {
      if (this.TcpSocket.Connected) {
        this.orderId = this.orderId + 1
        this.thisLastSendTime = new Date()
        data.webFlowId = this.orderId
        /** 设置终端参数 */
        if (data.command === 33027) {
          for (let i = 0; i < data.params.length / 2; i++) {
            const element = data.params[i * 2]

            switch (element) {
              // case 0x0055: // 设置最高速度
              //   data.params[i * 2 + 1] = data.params[i * 2 + 1]
              //   break
              case 0x005B: // 预警提前量-超速
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 10
                break
              case 0x0057: // 设置连续驾驶时间门限
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60
                break
              case 0x0058: // 设置当天累计驾驶时间门限
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60
                break
              case 0x0059: // 设置最小休息时间
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60
                break
              case 0x005C: // 预警提前量-疲劳
                data.params[i * 2 + 1] = data.params[i * 2 + 1] * 60
                break
              default:
                break
            }
          }
        }
        this.TcpSocket.sendData(JSON.stringify(data), '0xE7')
      } else {
        this.thisReturnCnnText(this, '服务器连接出现问题。', 888)
      }
      /** 历史视频上传  提示用户 "导出文件后台生成中，完成后请到下载中心下载文件。" */
      if (data.command === 0x9206) {
        this.returnDownLoadList(this)
        return
      }
      /**
       * 视频部分指令不显示到指令信息中
       */
      if (data.command === 0x9101 ||data.command === 0x9102 || data.command === 0x9205 || data.command === 0x9201 || data.command === 0x9202) {
        return
      }
      const orderParams = JSON.parse(params)
      let orderText = sCmdTxt
      orderParams.terminalDetail.forEach((res, i) => {
        let item = orderParams.terminalPhoneNos[i]
        const value = res.split("#")
        const sVehicleNo = value[0] ? value[0] : '暂无车辆数据'
        const vehGroupName = value[1] ? value[1] : '暂无公司数据'
        const time = YHTools.DateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')

        /** 文本信息下发 */
        if (orderParams.command === 0X8300) {
          let text = ''
          const cmdText = opertionType.get(0X8300)
          text += `${cmdText.split('-')[1]}：${orderParams.params[0]},`
          text += `${cmdText.split('-')[2]}：${orderParams.params[1]},`
          sCmdTxt = `${orderText}_${text}`
        }
        /** 设置终端参数 */
        if (orderParams.command === 33027) {
          let text = ''
          for (let i = 0; i < orderParams.params.length / 2; i++) {
            const element = orderParams.params[i * 2]
            const cmdText = opertionType.get(element)
            text += `${cmdText.split('-')[1]}：${orderParams.params[i * 2 + 1]},`
          }
          sCmdTxt = `${orderText}_${text}`
        }

        this.InteractionDataOut(this, sVehicleNo, vehGroupName, time, sCmdTxt, this.orderId, item)

      })
    } catch (e) {
      console.error('GpsReceiveData  SendCmdToCenter  error: ' + e.message)
    }
  }

  /**
   * 下发指令 (与上面功能冲突|不需要显示在指令信息中) young 20.09.11
   * 应答上级平台消息 20200106新需求
   * @param {*} params
   */
  SendData(params, type) {
    try {
      if (this.TcpSocket.Connected) {
        const orderParams = JSON.parse(params)
        this.orderId = this.orderId + 1
        orderParams.webFlowId = this.orderId
        this.TcpSocket.sendData(JSON.stringify(orderParams), type)
        // 设置IC卡参数
        if (orderParams.command === 0X8300) {
          const sText = "设置IC卡参数"
          orderParams.terminalDetail.forEach((res, i) => {
            let item = orderParams.terminalPhoneNos[i]
            const value = res.split("#")
            const sVehicleNo = value[0] ? value[0] : '暂无车辆数据'
            const vehGroupName = value[1] ? value[1] : '暂无公司数据'
            const time = YHTools.DateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
            this.InteractionDataOut(this, sVehicleNo, vehGroupName, time, `${sText}_写卡参数*`, this.orderId, item)
          })

        }
      } else {
        this.thisReturnCnnText(this, '服务器连接出现问题。', 888)
      }
    } catch (e) {
      // TODO handle the exception
      console.error('GpsReceiveData  SendData  error: ' + e.message)
    }
  }
}
