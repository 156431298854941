import modules from './modules'
import VuexPersistence from 'vuex-persist'
import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
  modules: modules,
  getters: {
    downLoadList: (state) => state.vehicle.downLoadList,
    downLoadLength: (state) => state.vehicle.downLoadLength,
    screenCoefficient: (state) => state.vehicle.screenCoefficient,
    websocketOnError: (state) => state.vehicle.websocketOnError,
    getRefresh(state) {
      return state.vehicle // 返回了一个对象，所以是一个引用
    },
    terminalPropertiesList: (state) => state.vehicle.terminalPropertiesList,
    terminalFilters: (state) => Array.from(state.vehicle.terminalFilters, (item) => {
      return { text: item, value: item }
    }),
    terminalHardFilters: (state) => Array.from(state.vehicle.terminalHardFilters, (item) => {
      return { text: item, value: item }
    }),
    terminalSoftFilters: (state) => Array.from(state.vehicle.terminalSoftFilters, (item) => {
      return { text: item, value: item }
    }),
    nowMenuList: (state) => state.menu.nowMenuList,
    interactiveArr: (state) => state.vehicle.interactiveArr,

  },
  // plugins: [vuexLocal.plugin]
})
// // 刷新加载的方法
// const refresh = function () {
//   // 页面刷新重新加载路由
//   store.dispatch('add_Routes_Fresh')
// }
// refresh()
export default store
