/**
 * 缓存菜单信息
 *
 */
const addMenuCtr = {
  state: {
    menuObj: {}
  },
  mutations: {
    add_MenuCtr (state, param) {
      if (param) {
        state.menuObj = param
      }
    },
    clear_MenuCtr (state, param) {
      state.menuObj = null
    }
  },
  actions: {
    add_MenuCtr ({ commit }, param) {
      commit('add_MenuCtr', param)
    },
    clear_MenuCtr ({ commit }, param) {
      commit('clear_MenuCtr', param)
    }
  }
}
export default addMenuCtr
