/*
create by gw : 2018-10-17  用于创建于后台通信并且解析其回传的数据，同时存储解析后的数据对象

在整个项目中，只需要在根页面产生该对象即可

*/

import {
  GpsReceiveData
} from './gpsReceiveData.js'
import {
  YHTools,
  RefreshTypeEnum,
} from './Tools.js'
import parametersKey from './parameters.json'

import getPlace from '@/common/utils/getPlace.js'
import baseConfig from '@/api/static/config'

// 数据刷新回调对象定义    回调对象,   回调函数
export class RefreshCallBack {
  constructor(callObject = null, callbackFunc = null) {
    // 没有具体的回调对象需要传的话，这里可以传null
    this.CallObject = callObject
    // 请求回调函数（参数为对象,具体类型的具体定义见：REFRESH_CALLBACK_DEFINE）
    this.CallBackFunc = callbackFunc
  }

  DoCallBackFunc(data) {
    try {
      const obj = this.CallObject
      const func = this.CallBackFunc
      if (obj != null) {
        obj.func(data)
      } else {
        func(data)
      }
    } catch (e) {
      // TODO handle the exception
      console.error('yh_sdp  RefreshCallBack  DoCallBackFunc ' + e.message)
    }
  }
}

/**
 * purpose : 用于创建于后台通信并且解析其回传的数据，同时存储解析后的数据对象
 * callBack_ReturnCnnText 连接状态信息回调  参数(string sReturnText, int CnnStatus)  //CnnStatus 0数据失败 1正在交互数据 2交互数据完成
 */
export class yh_sdp {
  /**
   * 创建yh_sdp对象
   * @param {*} sMainSrcIp 连接websocket 地址ip
   * @param {*} userId  账号id
   * @param {*} userName 账号名称
   * @param {*} loginIp 账号登录ip 唯一识别客户端
   * @param {*} callBack_ReturnCnnText
   * 连接状态回调函数   回调函数参数( sReturnText, iCnnStatus)   (iCnnStatus为2时表示登录成功,999表示websocket连接关闭,888表示websocket连接报错)
   */
  constructor(sMainSrcIp, userId, userName, loginIp, callBackReturnCnnText) {
    // 接收数据回调
    this.thisReceive = new GpsReceiveData(sMainSrcIp, userId, userName, loginIp, this, this.GpsAddDataPacket, this.GpsInteractionDataOut, this.ReturnCnnText, this.GpsReturnDownLoadList)

    // 返回状态信息
    this.ReturnCnnTextCallBack = callBackReturnCnnText

    // 回调函数集合 key: RefreshTypeEnum     value: list[RefreshCallBack ]
    this.RefreshCallFunctionList = new Map()

    // 是否通过了登陆界面的账号密码登陆验证
    this.bIsPassLogin = false
  }

  /* -------------------------------------------------------------刷新数据相关流程操作-------------------------------------------------- */
  /**
   * 添加数据刷新回调
   * @param {RefreshTypeEnum} eRefreshType 数据的类型
   * @param {RefreshTypeEnum} callFunc 回调函数
   * @param {object} callObject 回调对象
   */
  AddRefreshCallBack(eRefreshType, callFunc, callObject = null) {
    const eobjl = []
    eobjl.push(new RefreshCallBack(callObject, callFunc))
    this.RefreshCallFunctionList.set(eRefreshType, eobjl)
    // let eobjl = this.RefreshCallFunctionList.get(eRefreshType);
    // if (eobjl != null) {
    // 	eobjl.push(new RefreshCallBack(callObject, callFunc));
    // } else {
    // 	eobjl = [];
    // 	eobjl.push(new RefreshCallBack(callObject, callFunc));
    // 	this.RefreshCallFunctionList.set(eRefreshType, eobjl);
    // }
  }

  /**
   *  执行数据刷新回调
   * @param {RefreshTypeEnum} eRefreshType 数据的类型
   * @param {object} data 数据
   */
  RefreshDataBack(eRefreshType, data) {
    const eobjl = this.RefreshCallFunctionList.get(eRefreshType)
    if (eRefreshType === 513) {}
    if (eobjl != null) {
      for (let i = 0; i < eobjl.length; ++i) {
        eobjl[i].DoCallBackFunc(data)
      }
    }
  }

  /**
   * 删除数据刷新回调
   * @param {RefreshTypeEnum} eRefreshType eRefreshType 数据的类型
   * @param {Function} callFunc 回调函数
   */
  RemoveRefreshCallBack(eRefreshType, callFunc) {
    const eobjl = this.RefreshCallFunctionList.get(eRefreshType)
    if (eobjl != null) {
      for (let i = 0; i < eobjl.length; ++i) {
        if (eobjl[i].CallBackFunc === callFunc) {
          eobjl.slice(i, 1)
        }
      }
    }
  }
  /* ---------------------------------------------------------------------------中心转发数据数据交互------------------------------------------------------------------------------ */

  /* -------------------------------------------------------------具体的中心转发数据解析函数-------------------------------------------------- */

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * 连接状态信息回调
   * @param {*} callObject 回调对象
   * @param {*} sReturnText 回调对象
   * @param {*} iCnnStatus 0-登陆失败  1-正在登陆  2-登陆成功  3-下载资料  888-服务器错误   999-服务器连接关闭
   */
  ReturnCnnText(callObject, sReturnText, iCnnStatus) {
    try {
      if (!callObject.bIsPassLogin && iCnnStatus === 2) {
        callObject.bIsPassLogin = true
      }

      // by gw 2018-10-19  后续看是不是需要把这个函数添加上
      if (callObject.ReturnCnnTextCallBack != null) {
        callObject.ReturnCnnTextCallBack(sReturnText, iCnnStatus)
      }
    } catch (e) {
      // TODO handle the exception
      console.error('yh_sdp  ReturnCnnText  error: ' + e.message)
    }
  }

  /**
   *  gps服务器——交互信息回调(下发)
   * @param {*} callObject 回调对象
   * @param {*} sVehicleNo 车牌号码
   * @param {*} vehGroupName 车辆所属公司
   * @param {*} sTime 时间
   * @param {*} sCmdTxt 命令
   * @param {*} orderId 指令id
   * @param {*} terminalNo 终端号
   */
  GpsInteractionDataOut(callObject, sVehicleNo, vehGroupName, sTime, sCmdTxt, orderId, terminalNo) {
    try {
      if (callObject.bIsPassLogin) {

        const data = {
          type: 0,
          param: {
            orderId: orderId,
            terminalNo: terminalNo,
            sVehNo: sVehicleNo,
            vehGroupName: vehGroupName,
            sTime: sTime,
            sCmdTxt: sCmdTxt
          }
        }
        callObject.RefreshDataBack(RefreshTypeEnum.GPS_InteractionDataOut, data)
      }
    } catch (e) {
      // TODO handle the exception
      console.error('yh_sdp  GpsInteractionDataOut  error: ' + e.message)
    }
  }

  /**
   * 指令信息回应——回调(终端回应)
   * @param {*} thisData 参数
   * @param {*} text   参数
   */
  GpsInteractionDataCallback(thisData, text) {
    try {
      if (this.bIsPassLogin) {
        const timeTemp = YHTools.DateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss')
        const {
          webFlowId,
          terminalPhoneNo
        } = thisData
        const data = {
          type: 1,
          param: {
            orderId: webFlowId,
            terminalNo: terminalPhoneNo,
            sTime: timeTemp,
            sNote: text
          }
        }
        this.RefreshDataBack(RefreshTypeEnum.GPS_InteractionDataOut, data)
      }
    } catch (e) {
      // TODO handle the exception
      console.error('yh_sdp  GpsInteractionDataOut  error: ' + e.message)
    }
  }

  /**
   * 下发指令——查询信息返回回调
   * @param {*} callObject 回调对象
   * @param {*} data 车辆参数
   */
  GPSReturnOfCommand(callObject, data) {
    try {
      if (callObject.bIsPassLogin) {
        callObject.RefreshDataBack(RefreshTypeEnum.GPS_ReturnOfCommand, {
          data: data
        })
      }
    } catch (e) {
      // TODO handle the exception
      console.error('yh_sdp  GPSReturnOfCommand  error: ' + e.message)
    }
  }

  /**
   * 历史视频上传
   * @param {*} callObject 回调对象
   * @param {*} data 车辆参数
   */
  GpsReturnDownLoadList(callObject) {
    try {
      if (callObject.bIsPassLogin) {
        callObject.RefreshDataBack(RefreshTypeEnum.GPS_DownLoadHistoryVideo)
      }
    } catch (e) {
      // TODO handle the exception
      console.error('yh_sdp  GpsReturnDownLoadList  error: ' + e.message)
    }
  }

  /**
   * gps服务器——数据返回回调函数
   * purpose----解析具体数据  然后给监听了刷新消息的页面进行通知（即调用回调函数）
   * @param {*} callObject
   * @param {*} dataType
   * @param {*} returnData
   * callBack_InteractionData 交互信息回调  参数(string sVehicleNo, string sTime, string sCmdTxt, string sNote)
   */
  GpsAddDataPacket(callObject, dataType, returnData) {
    try {
      if (callObject.bIsPassLogin) {
        const thisData = JSON.parse(returnData).data
        if (thisData != null) {
          switch (dataType) {
            case '0xE2': // 警报信息
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_Alarm, returnData)
              break

            case '0xE3': // 车辆拍照返回数据
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_Image, returnData)
              break
            case '0xE4': // 车辆上下线数据
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_Vehicle, thisData)
              break
              case '0xE5': // 提醒用户系统已更新
              callObject.RefreshDataBack(RefreshTypeEnum.GB_Remind, thisData)
              break
            case '0xEC': // 获取上级平台的消息数据(获取)

              callObject.RefreshDataBack(RefreshTypeEnum.GPS_PlatformCommunication, returnData)
              break
            case '0xEB': // 收到报警督办信息
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_AlarmSupervisionInformation, returnData)

              break
            case '0xE7': // 下发指令
              const result = JSON.parse(thisData.responseData)
              const resultCode = typeof (result.resultCode) === 'number' ? result.resultCode : 5

              /** 下发指令通用应答 */
              switch (resultCode) {
                // 0:成功 1：失败 2：消息有误 3：不支持 4：报警处理确认
                case 0:
                  callObject.GpsInteractionDataCallback(thisData, '应答成功')
                  break
                case 1:
                  callObject.GpsInteractionDataCallback(thisData, '应答失败')
                  break
                case 2:
                  callObject.GpsInteractionDataCallback(thisData, '应答失败,消息有误')
                  break
                case 3:
                  callObject.GpsInteractionDataCallback(thisData, '不支持')
                  break
                case 4:
                  callObject.GpsInteractionDataCallback(thisData, '报警处理确认')
                  break
                default:
                  break
              }

              // 有需要返回数据显示的
              switch (thisData.command) {
                case 0x8201: // 部标指令-点名
                  // 点名返回的位置数据
                  getPlace(result.longitude, result.latitude).then(res => {
                    callObject.GpsInteractionDataCallback(thisData, `当前位置:  ${res}`)
                  })
                  break

                case 33028: // 查询终端参数 =》 所有终端参数
                  let noteInfo = [];
                  let alarmInfo = [];
                  let netInfo = [];
                  const parameters = [...result.parameters]
                  if (parameters.length > 1) {
                    parametersKey.alarm.forEach(element => {
                      const parameterVal = parameters.find(item => element.id === item.id)
                      const flag = parameters !== null
                      let realVal = ''
                      switch (element.id) {
                        case 86:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          alarmInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 87:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          alarmInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 88:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          alarmInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 89:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          alarmInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 90:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          alarmInfo.push({
                            name: element.name,
                            value: realVal
                          })
                          break;
                        case 91:
                          realVal = flag ? Number(parameterVal.value) / 10 : "暂无数据"
                          alarmInfo.push({
                            name: element.name,
                            value: realVal
                          })
                          break;
                        case 92:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          alarmInfo.push({
                            name: element.name,
                            value: realVal
                          })
                          break;
                        default:
                          alarmInfo.push({
                            name: element.name,
                            value: flag ? parameterVal.value : '暂无数据'
                          })
                          break;
                      }
                    })
                    parametersKey.net.forEach(element => {
                      let parameterVal = parameters.find(item => element.id === item.id);
                      let flag = parameters !== null;
                      let realVal = '';
                      let value = parameterVal ? parameterVal.value : "";
                      switch (element.id) {
                        case 1:
                          realVal = flag ? YHTools.FormatTimeBySeconds(value) : " 00:00:00"
                          netInfo.push({
                            name: element.name,
                            value: realVal
                          })
                          break;

                        case 40:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          netInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 41:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          netInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 44:
                          netInfo.push({
                            name: element.name,
                            value: flag ? `${parameterVal.value} 米` : '暂无数据'
                          })

                          break;
                        default:
                          netInfo.push({
                            name: element.name,
                            value: flag ? parameterVal.value : '暂无数据'
                          })

                          break;
                      }
                    })
                    parametersKey.other.forEach(element => {
                      let parameterVal = parameters.find(item => element.id === item.id);
                      let flag = parameters !== null;
                      let realVal = '';
                      let value = parameterVal ? parameterVal.value : "";
                      switch (element.id) {
                        case 2:
                          realVal = flag ? YHTools.FormatTimeBySeconds(value) : " 00:00:00"
                          noteInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 6:
                          realVal = flag ? YHTools.FormatTimeBySeconds(value) : " 00:00:00"
                          noteInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 32:
                          switch (value) {
                            case 0:
                              realVal = "定时汇报";
                              break;
                            case 1:
                              realVal = "定距汇报";
                              break;
                            case 2:
                              realVal = "定时和定距汇报";
                              break;
                            default:
                              realVal = "暂无数据";
                              break;
                          }
                          noteInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;

                        case 33:
                          switch (value) {
                            case 0:
                              realVal = "根据ACC状态";
                              break;
                            case 1:
                              realVal = "根据登录状态和ACC状态,先判断登录状态,若登录再根据ACC状态";
                              break;
                            default:
                              realVal = "暂无数据";
                              break;
                          }
                          noteInfo.push({
                            name: element.name,
                            value: realVal
                          })
                          break;
                        case 34:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          noteInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 39:
                          realVal = flag ? YHTools.FormatTimeBySeconds(parameterVal.value) : " 00:00:00"
                          noteInfo.push({
                            name: element.name,
                            value: realVal
                          })

                          break;
                        case 128:
                          noteInfo.push({
                            name: element.name,
                            value: flag ? `${Number(parameterVal.value) / 10} km` : '暂无数据'
                          })

                          break;
                        case 132:
                          switch (value) {
                            case 1:
                              realVal = "蓝色";
                              break;
                            case 2:
                              realVal = "黄色";
                              break;
                            case 3:
                              realVal = "白色";
                              break;
                            case 4:
                              realVal = "黄色";
                              break;
                            default:
                              realVal = "其他";
                              break;
                          }
                          noteInfo.push({
                            name: element.name,
                            value: realVal
                          })
                          break;
                        default:
                          noteInfo.push({
                            name: element.name,
                            value: flag ? parameterVal.value : '暂无数据'
                          })

                          break;
                      }

                    })
                    let note = {
                      alarm: alarmInfo,
                      net: netInfo,
                      other: noteInfo,
                    };
                    callObject.GpsInteractionDataCallback(thisData, note)
                  } else {
                    // 查询终端参数报警屏蔽设置
                    callObject.RefreshDataBack(RefreshTypeEnum.GB_QueryAlarmSeting, parameters[0])
                  }
                  break
                case 0x8107: // 查询终端属性 =》 所有终端属性  查询终端版本信息
                  /**单个查询 - 终端升级 */
                  callObject.RefreshDataBack(RefreshTypeEnum.GB_QueryAttributes, {
                    result: result,
                    device: thisData.terminalPhoneNo,
                    vehicleNo: thisData.vehicleNo
                  });
                  /**批量 下发指令查询1-100条 */
                  callObject.RefreshDataBack(RefreshTypeEnum.GB_QueryTerminalProperties, {
                    result: result,
                    device: thisData.terminalPhoneNo,
                    vehicleNo: thisData.vehicleNo
                  });
                  let info = [{
                      name: "终端型号",
                      value: result.terminalModel
                    },
                    {
                      name: "终端ID",
                      value: result.terminalID
                    },
                    {
                      name: "终端SIM_ICCID",
                      value: result.SIM_ICCID
                    },
                    {
                      name: "硬件版本号",
                      value: result.hardwareVersion
                    },
                    {
                      name: "固件版本号",
                      value: result.softwareVersion
                    }
                  ]
                  callObject.GpsInteractionDataCallback(thisData, info)

                  break

                case 0x9101: // 实时音视频传输请求 =》 查看实时视频请求
                  const paramData = {
                    channel: null,
                    device: thisData.terminalPhoneNo,
                    plate: 'veh'
                  }
                  switch (resultCode) {
                    case 0:
                      callObject.RefreshDataBack(RefreshTypeEnum.GPS_RealVideo, {
                        flag: true,
                        data: paramData
                      })
                      break
                    case 1:
                      callObject.RefreshDataBack(RefreshTypeEnum.GPS_RealVideo, {
                        flag: false,
                        data: paramData
                      })

                      break
                    case 2:
                      callObject.RefreshDataBack(RefreshTypeEnum.GPS_RealVideo, {
                        flag: false,
                        data: paramData
                      })

                      break
                    case 3:
                      callObject.RefreshDataBack(RefreshTypeEnum.GPS_RealVideo, {
                        flag: false,
                        data: paramData
                      })

                      break

                    case 4:
                      callObject.RefreshDataBack(RefreshTypeEnum.GPS_RealVideo, {
                        flag: false,
                        data: paramData
                      })

                      break
                    case 5:
                      callObject.RefreshDataBack(RefreshTypeEnum.GPS_RealVideo, {
                        flag: false,
                        data: paramData
                      })

                      break
                  }

                  break
                case 0x9205:
                  callObject.RefreshDataBack(RefreshTypeEnum.GPS_QueryTerminalResList, result.res)

                  break

                case 0x9201: // 历史音视频传输请求 =》 历史视频请求 正常情况返回资源列表 非正常情况通用应答
                  let resultVideo = null
                  if (result.res.length) {
                    resultVideo = result.res.length
                  } else if (result.resultCode === 0) {
                    resultVideo = 1
                  }
                  if (resultVideo > 0) {
                    callObject.RefreshDataBack(RefreshTypeEnum.GPS_HistoryVideo, {
                      flag: true,
                      data: '车辆'
                    })
                  } else {
                    callObject.RefreshDataBack(RefreshTypeEnum.GPS_HistoryVideo, {
                      flag: false,
                      data: '终端'
                    })
                  }

                  break

                default:
                  break
              }
              break
            case '0xE8': // 车辆上下线数据
              callObject.RefreshDataBack(RefreshTypeEnum.GPS_Logout, thisData)
              break

            default:

              break
          }
          // callObject.thisReceive.orderVehcileList.delete(thisData.terminalPhoneNo)
        }
      } else {
        console.log('没登陆,重连')
      }
    } catch (e) {
      // TODO handle the exception
      console.error('yh_sdp  GpsAddDataPacket  error: ' + e.message)
    }
  }

  /**
   *  连接websocket
   */
  ConnectSocketServer(flag) {
    try {
      this.thisReceive.SocketConnect(true, false)
      if (this.thisReceive) this.thisReceive.bDownLoadLast = true
      if (flag) {
        this.thisReceive.websocketOnError = 0
      }
    } catch (e) {
      console.error('yh_sdp  ConnectSocketServer  error: ' + e.message)
    }
  }

  /**
   *  关闭socket连接
   */
  CloseWebsocketLink() {
    if (this.thisReceive != null) {
      this.thisReceive.SocketConnect(false, false)

      this.thisReceive = null
    }
  }

  /**
   * 关闭网络连接
   */
  CloseSocketLink() {
    this.bIsPassLogin = false
    this.CloseWebsocketLink()
  }

  /// ////////////////////////////////////////////////////////////////////设备参数设置//////////////////////////////////////////////////////////////////////////////////

  /**
   * 通过websocket发送消息----发送指令(不需要显示在指令信息中)
   * @param {*} params 参数
   * @param {*} type   '0xE7'|'0xEC'
   */
  SendData(params, type) {
    if (this.thisReceive != null) {
      this.thisReceive.SendData(params, type)
    }
  }

  /**
   * 通过websocket发送消息----发送指令
   * @param {string} params 参数
   * @param {string} sCmdTxt 命令
   * @param {string} sNote 内容
   */
  SendSocketData(params, sCmdTxt, sNote) {
    if (this.thisReceive != null) {
      this.thisReceive.SendCmdToCenter(params, sCmdTxt, sNote)
    }
  }

  /**
   * 部标设备指令-点名
   *@param {Array} terminalNos 需要操作的车辆设备号数组
   */
  GBCalling(terminalNos, terminalType) {
    try {
      const objTemp = {
        command: 0X8201, // msgId,num类型
        params: [], // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendSocketData(JSON.stringify(objTemp), '点名', '')
    } catch (error) {
      console.error('yh_sdp  Calling  error: ' + error.message)
    }
  }

  /**
   * 部标设备指令-文本信息下发(批量处理报警--相同车辆只发送一次指令)
   * @param {Array} terminalNos 需要操作的车辆设备号数组
   * @param {string} sDispatchType 调度类型  紧急,保留,终端显示器显示,终端TTS播读,广告屏显示,IC卡关联操作,显示+播报  中的一个
   * @param {string} sText 调度内容
   */

  GBSendMessage(terminalNos, sDispatchType, sText, terminalType) {
    try {
      const objTemp = {
        command: 0x8300, // msgId,num类型
        params: [sDispatchType, sText], // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendSocketData(JSON.stringify(objTemp), '文本信息下发', `${sDispatchType}-${sText}`)
    } catch (error) {
      console.error('yh_sdp  GB_SendMessage  error: ' + error.message)
    }
  }

  /**
   * 部标设备指令-查询终端参数
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBQueryParameters(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0X8104, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendSocketData(JSON.stringify(objTemp), '查询终端参数', '')
    } catch (error) {
      console.error('yh_sdp  GB_SetVehicleDate  error: ' + error.message)
    }
  }

  /**
   * 部标设备指令-设置终端参数(整体，文本信息在SendCmdToCenter方法中写)
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBSetParameters(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8103, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendSocketData(JSON.stringify(objTemp), '设置终端参数')
    } catch (error) {
      console.error('yh_sdp  GBSetParameters  error: ' + error.message)
    }
  }

  /**
   * 部标设备指令-查询终端参数-查询终端参数报警屏蔽设置
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBQueryAlarmSeting(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0X8104, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendData(JSON.stringify(objTemp), '0xE7')
    } catch (error) {
      console.error('yh_sdp  GB_SetVehicleDate  error: ' + error.message)
    }
  }

  /**
   * 部标设备指令-设置终端参数-设置终端参数报警屏蔽
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBSetAlarmSeting(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8103, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendData(JSON.stringify(objTemp), '0xE7')
    } catch (error) {
      console.error('yh_sdp  GBSetParameters  error: ' + error.message)
    }
  }


  /**
   * 部标设备指令 - 查询终端属性(查询终端版本信息)
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBQueryAttributes(terminalNos, terminalType) {
    try {
      const objTemp = {
        command: 0x8107, // msgId,num类型
        params: null, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendSocketData(JSON.stringify(objTemp), '查询终端属性')
    } catch (error) {
      console.error('yh_sdp  GBQueryAttributes  error: ' + error.message)
    }
  }

  /**
   * 部标设备指令 - 升级终端
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBTermainalUpdate(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0x8899, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendSocketData(JSON.stringify(objTemp), '终端升级')
    } catch (error) {
      console.error('yh_sdp  GBTermainalUpdate  error: ' + error.message)
    }
  }

  /**
   * 部标设备指令 - 设置IC卡参数
   * @param {*} terminalNos 需要操作的车辆设备号数组
   * @param {*} params 子级指令和参数
   */
  GBSetICParam(terminalNos, params, terminalType) {
    try {
      const objTemp = {
        command: 0X8300, // msgId,num类型
        params: params, // 发送内容
        terminalType: terminalType, // 发送内容
        terminalPhoneNos: terminalNos[0], // 需下发的手机号
        terminalDetail: terminalNos[1], // 需回显的信息
      }
      this.SendData(JSON.stringify(objTemp), '0xE7')
    } catch (error) {
      console.error('yh_sdp  GBSetICParam  error: ' + error.message)
    }
  }
  /** *****************************************************查看视频指令************************************************************8 */

  /**
   * 实时音视频传输请求
   * @param {*} params
   * @param {*} isHD
   */
  GBRealVideoStreamReq(params, isHD, terminalType) {
    try {
      const param6 = (isHD === '高清') ? 0 : 1
      const reqParams = [
        baseConfig.videoBaseUrl, // 实时视频服务器IP地址
        params.port, // 实时视频服务器TCP端口号
        params.port, // 实时视频服务器UDP端口号
        Number(params.channel), // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        0, // 数据类型（0：音视频 1：视频 2：双向对讲 3：监听 4：中心广播 5：透传）
        param6 // 码流类型（0：主码流 1：子码流）
      ]
      const objTemp = {
        command: 0x9101, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      }

      this.SendSocketData(JSON.stringify(objTemp), '实时音视频传输请求')
    } catch (error) {
      console.error('yh_sdp  GSMNO  error: ' + error.message)
    }
  }


  /**
   * 视频清晰度切换
   * 实时音视频传输控制(码流切换)
   * @param {*} params
   * @param {*} isHD
   */
  GBRealVideoStreamControl(params, isHD) {
    try {
      const param4 = (isHD === '高清') ? 0 : 1
      const reqParams = [
        Number(params.channel), // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：	车厢中部 12：车中门 13：驾驶席车门）
        1, // 控制指令（0：关闭视音频传输请求 1：切换码流 2：暂停该通道所有流的发送 3：恢复暂停前流的发送 4：关闭双向对讲）
        0, // 关闭音视频类型（0：关闭音视频 1：关闭音频 2：关闭视频）
        param4 // 切换码流类型（0：主码流 1：子码流）
      ]
      const objTemp = {
        command: 0x9102, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      }
      this.SendSocketData(JSON.stringify(objTemp), '实时音视频视频清晰度切换')
    } catch (error) {
      console.error('yh_sdp  GSMNO  error: ' + error.message)
    }
  }

  /**
   * 关闭全部实时视频请求
   * @param {*} params
   */
  GBRealVideoStreamControlAll(params) {
    try {
      const reqParams = [
        Number(params.channel), // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：	车厢中部 12：车中门 13：驾驶席车门）
        0, // 控制指令（0：关闭视音频传输请求 1：切换码流 2：暂停该通道所有流的发送 3：恢复暂停前流的发送 4：关闭双向对讲）
        0, // 关闭音视频类型（0：关闭音视频 1：关闭音频 2：关闭视频）
        0 // 切换码流类型（0：主码流 1：子码流）
      ]
      const objTemp = {
        command: 0x9102, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      }
      this.SendSocketData(JSON.stringify(objTemp), '关闭全部实时视频请求')
    } catch (error) {
      console.error('yh_sdp  GSMNO  error: ' + error.message)
    }
  }

  /**
   * 查询终端资源列表
   * @param {*} params
   */
  GBQueryTerminalResList(params) {
    try {
      const reqParams = [
        params.channel, // 逻辑通道号，0表示所有通道，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        params.begintime, // 开始时间（时间戳精度：秒）
        params.endtime, // 结束时间
        '', // 基础报警标志，与“部标指令-设置终端参数-设置报警屏蔽位”类似，一个或多个，‘|’相隔，无空格（紧急报警|超速报警|疲累报警|预警|GNSS故障报警|GNSS天线开路报警|GNSS天线短路报警|终端主电源欠压报警|终端主电源掉电报警|终端LCD故障报警|TTS模块故障报警|摄像头故障报警|当天累计驾驶超时报警|停车超时报警|进出区域报警|进出路线报警|路段行驶时间不足|路线偏离报警|车辆VSS故障报警|车辆油量异常报警|盗警|非法点火报警|非法移位报警|碰撞侧翻报警）
        '', // 扩展视频报警标志，一个或多个，‘|’相隔，无空格（视频信号丢失|视频信号遮挡|存储单元故障|其他视频设备故障|客车超员|异常驾驶|特殊报警录像达到存储阈值）
        3, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
        0, // 码流类型（0：所有码流 1：主码流 2：子码流）
        0 // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
      ]
      const objTemp = {
        command: 0x9205, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      }
      this.SendSocketData(JSON.stringify(objTemp), '查询终端资源列表(历史视频)')
    } catch (error) {
      console.error('yh_sdp  GSMNO  error: ' + error.message)
    }
  }

  /**
   * 平台下发远程录像回放请求(*播放)
   * @param {*} device
   * @param {*} channel
   * @param {*} beginTime
   * @param {*} endTime
   * @param {*} resType
   * @param {*} streamType
   * @param {*} storageType
   * @param {*} number
   */
  GBHistoryStreamReq(device, channel, beginTime, endTime, resType, streamType, storageType, number) {
    try {
      const reqParams = [
        baseConfig.videoBaseUrl, // 实时视频服务器IP地址
        baseConfig.videoHistoryPort, // 实时视频服务器TCP端口号，不使用TCP传输时填0
        baseConfig.videoHistoryPort, // 实时视频服务器UDP端口号，不使用UDP传输时填0
        channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        resType, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
        streamType, // 码流类型，如果此通道只传输音频，则填0（0：主码流或子码流 1：主码流 2：子码流）
        storageType, // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
        0, // 回放方式（0：正常回放 1：快进回放 2：关键帧快退回放 3：关键帧播放 4：单帧上传）
        number, // 快进或快退倍数（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
        beginTime, // 开始时间（回放方式为4时，该字段表示单帧上传时间）
        endTime // 结束时间（此字段为0时表示一直回放，回放方式为4时，该字段无效）
      ]
      const objTemp = {
        command: 0x9201, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [device] // 需下发的手机号
      }
      this.SendSocketData(JSON.stringify(objTemp), '平台下发远程录像回放请求(历史视频)')
    } catch (error) {
      console.error('yh_sdp  GSMNO  error: ' + error.message)
    }
  }

  /**
   * 平台下发远程录像回放控制(停止)
   * @param {*} params
   */
  GBHistoryStreamControl(params) {
    try {
      const reqParams = [
        params.channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        2, // 回放控制（0：开始回放 1：暂停回放 2：结束回放 3：快进回放 4：关键帧快退回放 5：拖动回放 6：关键帧播放
        1, // 快进或快退倍数，回放控制为3或4时，此字段有效，否则置0（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
        0 // 回放控制为5时，此字段有效，时间戳精度：秒
      ]
      const objTemp = {
        command: 0x9202, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [params.device] // 需下发的手机号
      }
      this.SendSocketData(JSON.stringify(objTemp), '历史视频关闭请求')
    } catch (error) {
      console.error('yh_sdp  GSMNO  error: ' + error.message)
    }
  }

  /**
   * 平台下发远程录像回放控制(拖动回放)
   * @param {*} device
   * @param {*} channel
   * @param {*} number
   */
  GBHistoryStreamSpeedControl(device, channel, number) {
    try {
      const reqParams = [
        channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        5, // 回放控制（ 3：快进回放 4：关键帧快退回放 5：拖动回放 6：关键帧播放
        0, // 快进或快退倍数，回放控制为3或4时，此字段有效，否则置0（0：无效 1：1倍 2：2倍 3：4倍 4：8倍 5：16倍）
        number // 回放控制为5时，此字段有效，时间戳精度：秒
      ]
      const objTemp = {
        command: 0x9202, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [device] // 需下发的手机号
      }
      this.SendSocketData(JSON.stringify(objTemp), '历史视频控制请求')
    } catch (error) {
      console.error('yh_sdp  GSMNO  error: ' + error.message)
    }
  }

  /**
   * 文件上传(主要是历史视频)
   * @param {*} device
   * @param {*} channel
   * @param {*} beginTime
   * @param {*} endTime
   * @param {*} resType
   * @param {*} streamType
   * @param {*} storageType
   */
  downLoadHistoryFile(device, channel, beginTime, endTime, resType, streamType, storageType) {
    try {
      // 指令content : 逻辑通道,开始时间, 结束时间, 音视频资源类型, 码流类型, 存储类型, 执行任务条件(wifi|lan|4G),用户id
      const reqParams = [
        channel, // 逻辑通道号，详见JT/T 1076-2016表2（1：驾驶员 2：车辆正前方 3：车前门 4：车厢前部 5：车厢后部 6：车后门 7：行李舱 8：车辆左侧 9：车辆右侧 10：车辆正后方 11：车厢中部 12：车中门 13：驾驶席车门）
        beginTime, // 开始时间（回放方式为4时，该字段表示单帧上传时间）
        endTime, // 结束时间（此字段为0时表示一直回放，回放方式为4时，该字段无效）
        resType, // 音视频资源类型（0：音视频 1：音频 2：视频 3：视频或音视频）
        streamType, // 码流类型，如果此通道只传输音频，则填0（0：主码流或子码流 1：主码流 2：子码流）
        storageType, // 存储器类型（0：所有存储器 1：主存储器 2：灾备存储器）
        'wifi|lan|4G' // 执行任务条件(wifi|lan|4G)
      ]
      const objTemp = {
        command: 0x9206, // msgId,num类型
        params: reqParams, // 发送内容
        terminalType: sessionStorage.getItem("system"), // 发送内容
        terminalPhoneNos: [device] // 需下发的手机号
      }
      this.SendSocketData(JSON.stringify(objTemp), '历史视频上传请求')
    } catch (error) {
      console.error('yh_sdp  GSMNO  error: ' + error.message)
    }
  }
}
