/**
 * 动态添加菜单
 * author:qlx
 */
const addMenus = {
  state: {
    menuList: [],
    showUserHeader: true,
    nowMenuList: null,
    loadMenuList: null
  },
  mutations: {
    add_Menus (state, param) {
      if (param) {
        state.menuList = param
      }
    },
    now_Menus (state, param) {
      if (param) {
        state.nowMenuList = param
      }
    },
    load_MenuTree (state, param) {
      if (param) {
        state.loadMenuList = param
      }
    },
    clear_Menus (state) {
      state.menuList = []
      state.nowMenuList = null
      state.showUserHeader = true
    },
    change_UserHeader (state, param) {
      state.showUserHeader = false
    }
  },
  actions: {
    add_Menus ({ commit }, param) {
      commit('add_Menus', param)
    },
    now_Menus ({ commit }, param) {
      commit('now_Menus', param)
    },
    load_MenuTree ({ commit }, param) {
      commit('load_MenuTree', param)
    },
    clear_Menus ({ commit }, param) {
      commit('clear_Menus', param)
    },
    change_UserHeader ({ commit }, param) {
      commit('change_UserHeader', param)
    }
  }
}
export default addMenus
