import service from './refresh'
//接口前缀
const BASE_PREFIX = '/base';

/********************实时监控模块****************/


// 实时更新树
const queryAllNode = data => {
  return service({
    url: `${BASE_PREFIX}/tree/queryAllNode`,
    method: 'get',
    data
  })
}

// 车辆树
const getVehicleGroupTree = data => {
  return service({
    url: `${BASE_PREFIX}/tree/queryNode`,
    method: 'get',
    data
  })
}

// 模糊搜索下拉框
const searchTree = data => {
  return service({
    url: `${BASE_PREFIX}/tree/search`,
    method: 'get',
    data
  })
}

// 综合监控 获取动态车辆位置
const getPostionVhiclePositions = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/vehiclePositions`,
    method: 'get',
    data
  })
}

// 综合监控 查询实时警报
const queryRealTimeAlarm = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/realTimeAlarms`,
    method: 'get',
    data
  })
}


// 综合监控 离线提醒表格
const getOfflineRemind = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/offline`,
    method: 'get',
    data
  })
}

// 综合监控 树的车辆状态更新
const getVhicleStatus = data => {
  return service({
    url: `${BASE_PREFIX}/tree/online`,
    method: 'get',
    data
  })
}

// 轨迹回放列表数据查询
const getHistoryTrack = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyTrack`,
    method: 'get',
    data
  })
}

// 轨迹回放地图点位查询
const getHistoryTrackView = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyTrackView`,
    method: 'get',
    data
  })
}

// 轨迹回放列表总数
const positionCount = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/positionCount`,
    method: 'get',
    data
  })
}

// 根据车辆id(v1)或者公司id查询车辆终端信息*(下发指令用)
const queryDeviceByIds = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/devices`,
    method: 'get',
    data
  })
}

// 根据终端号查询车辆信息*(下发指令--视频返回用)
const queryVehicleByDeviceNo = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryVehicleByDeviceNo`,
    method: 'get',
    data
  })
}

/**
 * 历史视频上传状态列表
 *
 */

const getHistoryVideoList = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyVideos`,
    method: 'get',
    data
  })
}
/**
 * 历史视频上传状态列表
 *
 */

const historyVideoCheck = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/historyVideoCheck`,
    method: 'post',
    data
  })
}

/**
 * 播放视频模块--通知后台关流(出租车)
 *
 */

const closeVehicleVideo = data => {
  return service({
    url: `${BASE_PREFIX}/vehicleVideo/close`,
    method: 'post',
    data
  })
}

/**
 * 车辆选择组件--模糊搜索车辆
 *
 */

const searchVehicleFuzzy = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryVehicleByCph?cph=${data}`,
    method: 'get'
  })
}

/**
 * 车辆选择组件--模糊搜索车辆
 *
 */

const queryVehicleFuzzy = data => {
  return service({
    url: `${BASE_PREFIX}/vehicle/queryVehicleByCph`,
    method: 'get',
    data
  })
}

/**
 * checkVideoStreams
 *
 */

const checkVideoStreams = data => {
  return service({
    url: `${BASE_PREFIX}/video/checkVideoStreams`,
    method: 'post',
    data
  })
}

/**
 * 添加抽查信息
 *
 */

const addSpotCheck = data => {
  return service({
    url: `${BASE_PREFIX}/monitor/spotCheck`,
    method: 'get',
    data
  })
}

// 获取【车辆信息】车辆卡片
const queryVehicleCardById = data => {
  return service({
    url: '/base/monitor/vehicleCard',
    method: 'get',
    data
  })
}

export {
  queryAllNode,
  getVehicleGroupTree,
  searchTree,
  getPostionVhiclePositions,
  queryRealTimeAlarm,
  getOfflineRemind,
  getVhicleStatus,
  getHistoryTrack,
  getHistoryTrackView,
  positionCount,
  queryDeviceByIds,
  queryVehicleByDeviceNo,
  getHistoryVideoList,
  historyVideoCheck,
  closeVehicleVideo,
  searchVehicleFuzzy,
  queryVehicleFuzzy,
  checkVideoStreams,
  addSpotCheck,
  queryVehicleCardById
}


/* ----------------- 表格导出及下载模块 ---------------------- */
/**
 * 添加导出文件记录
 *
 */
const addExcelExport = data => {
  let parma = {
    ...data
  }
  delete data.baseUrl
  return service({
    url: `${BASE_PREFIX}/excelExport/insert`,
    method: 'post',
    from: parma.baseUrl,
    data
  })
}

/**
 * 删除导出文件记录
 *
 */
const delExcelExport = data => {
  return service({
    url: `${BASE_PREFIX}/excelExport/delete`,
    method: 'post',
    data
  })
}

/**
 * 修改导出文件记录
 *
 */
const updateExcelExport = data => {
  return service({
    url: `${BASE_PREFIX}/excelExport/update`,
    method: 'post',
    data
  })
}

/**
 * 分页查询文件导出记录
 *
 */
const queryExcelExportList = data => {
  return service({
    url: `${BASE_PREFIX}/excelExport/queryPageList`,
    method: 'post',
    data
  })
}



export {
  addExcelExport,
  delExcelExport,
  updateExcelExport,
  queryExcelExportList
}
