/*
create by gw : 2018-09-13  用于存放、实现工具函数

/**
 * 实时信息枚举
 */


export const RefreshTypeEnum = Object.freeze({
  GPS_Vehicle: 0x100, // gps车辆上下线提醒 256
  GPS_Image: 0x101, // gps图像实时数据 257
  GPS_Alarm: 0x102, // gps报警实时数据
  GPS_InteractionDataOut: 0x103, // gps交互信息
  GPS_SendSingeName: 0x105, // gps发送登签语音播报
  GPS_AlarmSupervisionInformation: 0x106, // gps平台交流,报警督办信息
  GPS_PlatformCommunication: 0x107, // gps平台交流,暂时只做上下级交流
  GPS_ReturnOfCommand: 0x108, // gps下发指令,需要返回数据的
  GPS_Logout: 0x109, // gps下发指令,需要返回数据的
  GPS_QueryTerminalResList: 0x200, // 查看历史视频,需要获取返回资源列表
  GPS_RealVideo: 0x201, // 查看实时视频,成功或失败
  GPS_HistoryVideo: 0x202, // 查看历史视频,成功或失败
  GPS_DownLoadHistoryVideo: 0x203, // 历史视频上传,
  GB_QueryAlarmSeting: 0x204, // 查询终端参数报警屏蔽设置,
  GB_QueryAttributes: 0x205, //查询终端属性,
  GB_QueryTerminalProperties: 0x206, //查询终端属性,
  GB_TerminalInterval: 0x207, //开始执行等待查询终端属性的结果,
  GB_Remind: 0x208, //提醒用户系统已更新

})

/**
 * 操作指令相对应的英文字符
 */
export const opertionType = new Map([
  [0X8201, '点名'],
  [0X8300, '文本信息下发-消息类型-消息内容'],
  [0X8104, '查询终端参数'],
  [0x8103, '设置终端参数'],
  [0x8107, '查询终端属性'],
  [0x8899, '终端升级'],
  [0xFEEB, '设置IC卡参数'],
  [0x0055, '设置终端参数-设置最高速度(km/h)'],
  [0x005b, '设置终端参数-设置预超速提前量(km/h)'],
  [0x0056, '设置终端参数-设置超速持续时间(s)'],
  [0x0057, '设置终端参数-设置连续驾驶时间(分钟)'],
  [0x005c, '设置终端参数-设置预疲劳提前量(分钟)'],
  [0x0058, '设置终端参数-设置当天累计驾驶时间(分钟)'],
  [0x0059, '设置终端参数-设置最小休息时间(分钟)'],
  [0x0001, '设置终端参数-设置心跳间隔(s)'],
  [0x0010, '设置终端参数-设置主服务器APN '],
  [0x0013, '设置终端参数-设置主服务器IP或域名 '],
  [0x0018, '设置终端参数-设置主服务器端口 '],
  [0x9101, '实时音视频传输请求'],
  [0x9205, '查询终端资源列表(历史视频)'],
  [0x9201, '平台下发远程录像回放请求(历史视频)'],
  [0x9202, '历史视频控制请求'],
  [0x9206, '历史视频上传请求']
])

/**
 * 设置终端参数 =》 所有终端参数
 */
export const alarmShield = [
  '紧急报警、触动报警开关后触发',
  '预警',
  '卫星定位模块发生故障',
  '卫星定位天线未接或被剪断',
  '卫星定位天线短路',
  'ISU主电源欠压',
  'UISU主电源掉电',
  '液晶(LCD)显示ISU故障',
  '语音合成(TTS)模块故障',
  '摄像头故障',
  '计价器故障',
  '服务评价器故障(前后排)',
  'LED广告屏故障',
  '液晶(LCD)显示屏故障',
  '安全访问模块故障',
  'LED顶灯故障',
  '超速报警',
  '连续驾驶超时',
  '当天累计驾驶超时',
  '超时停车',
  '进出区域/路线',
  '路段行驶时间不足/过长',
  '禁行路段行驶',
  '车速传感器故障',
  '车辆非法点火',
  '车辆非法位移',
  'ISU存储异常',
  '录音设备故障',
  '计价器时钟超过规定误差'
]

export class YHTools {
  /**
   * 日期格式化输出
   * @param {date} dtTime
   * @param {string} sFormat
   */
  static DateFormat(dtTime, sFormat) {
    try {
      const obj = {
        'M+': dtTime.getMonth() + 1, // 月份
        'd+': dtTime.getDate(), // 日
        'H+': dtTime.getHours(), // 小时
        'm+': dtTime.getMinutes(), // 分
        's+': dtTime.getSeconds(), // 秒
        'q+': Math.floor((dtTime.getMonth() + 3) / 3), // 季度
        S: dtTime.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(sFormat)) {
        sFormat = sFormat.replace(RegExp.$1, (`${dtTime.getFullYear()}`).substr(4 - RegExp.$1.length))
      }
      Object.keys(obj).forEach((key) => {
        if (new RegExp(`(${key})`).test(sFormat)) {
          sFormat = sFormat.replace(RegExp.$1, RegExp.$1.length === 1 ? obj[key] : (`00${obj[key]}`).substr(`${obj[key]}`.length))
        }
      })
      return sFormat
    } catch (error) {
      console.error('Tools  DateFormat  error: ' + error.message)
      return ''
    }
  }

  /**
   * 根据秒获取小时 分钟 秒
   * 最小为1分钟(60),最大值4个小时15分钟(15300)
   * @param {number} uiSeconds
   * @param {boolean} flag   输出00：00：00 或  xx天xx小时xx分钟xx秒
   */
  static FormatTimeBySeconds(uiSeconds, flag) {
    try {
      var s = 0 // 秒
      var m = 0 // 分
      var h = 0 // 小时
      var d = 0 // 天
      if (uiSeconds >= 60) {
        m = parseInt(uiSeconds / 60)
        s = parseInt(uiSeconds % 60)
        if (m >= 60) {
          h = parseInt(m / 60)
          m = parseInt(m % 60)
          if (h >= 24 && flag) {
            d = parseInt(h / 24)
            h = parseInt(h % 24)
          }
        }
      } else {
        s = uiSeconds
      }
      const seconds = s ? `${s} 秒 ` : '' // 秒
      const minutes = m ? `${m} 分钟 ` : '' // 分
      const hours = h ? `${h} 小时 ` : '' // 小时
      const days = d ? `${d} 天 ` : '' // 天
      const str = `${days}${hours}${minutes}${seconds}`

      const ho = h ? `${h.toString().padStart(2, '0')}:` : '00:' // 小时

      return flag ? str : `${ho}${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`
    } catch (error) {
      console.error('Tools  FormatTimeBySeconds  error: ' + error.message)
    }
  }
}
