<template>
  <div class="alarm-dia" :class="{ batch: isBatch }">
    <div v-if="!fileDialog">
      <div class="scroll" v-if="!isBatch">
        <p class="title header-title">
          <span>报警信息</span>
          <el-button
            type="primary"
            size="small"
            :disabled="detailForm.alarmDetailVoList.length == 0"
            @click="exportDetail"
            :loading="downLoadStatus"
            >导出</el-button
          >
        </p>
        <div class="information">
          <div class="head">
            <p>
              <span>车牌号：</span>
              <span>{{ detailForm.alarmBaseVO.cph }}</span>
            </p>
            <p>
              <span>所属车队：</span>
              <span>{{ detailForm.alarmBaseVO.companyName }}</span>
            </p>
            <p>
              <span>驾驶员：</span>
              <span>{{ detailForm.alarmBaseVO.driverName }}</span>
            </p>
            <p>
              <span>报警类型：</span>
              <span class="sign"
                >{{ detailForm.alarmBaseVO.alarmName
                }}{{
                  detailForm.alarmBaseVO.alarmType === 2012 &&
                  detailForm.alarmBaseVO.alarmSource === 2
                    ? "(路网)"
                    : ""
                }}</span
              >
            </p>
            <p>
              <span>报警等级：</span>
              <span class="sign">{{
                alarmLevelObj[Number(detailForm.alarmBaseVO.alarmLevel)]
              }}</span>
            </p>
            <p>
              <span>驾驶证号：</span>
              <span>{{ detailForm.alarmBaseVO.drivingLicense }}</span>
            </p>
          </div>
          <p class="split-line"></p>
          <div class="body">
            <el-table
              :data="detailForm.alarmDetailVoList"
              max-height="245px"
              class="no-blank alarm-table"
            >
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column
                prop="alarmSignTime"
                label="报警时间"
                width="160"
              ></el-table-column>
              <el-table-column
                prop="velocity"
                label="车速"
                width="60"
              ></el-table-column>
              <el-table-column
                v-if="detailForm.alarmBaseVO.alarmType === 2012"
                label="限速值"
                width="80"
                ><template>
                  {{ detailForm.alarmBaseVO.limitValue }}
                </template></el-table-column
              >
              <el-table-column
                prop="position"
                label="经纬度"
                width="180"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <span>{{
                    `${scope.row.longitude},${scope.row.latitude}`
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="address"
                label="位置信息"
                width="300"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                prop="vehStatus"
                label="车辆状态"
                width="350"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <!-- <el-table-column
                prop="alarmContent"
                label="报警附加信息"
                width="300"
                :show-overflow-tooltip="true"
              ></el-table-column> -->
              <el-table-column label="附件">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.alarmSignFileNum"
                    class="sign pointer"
                    @click="onShowAnnex(scope.row)"
                    >查看附件</span
                  >
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <p
          class="title"
          v-show="
            (alarmDetail && alarmDetail.disposeStatus !== 2) ||
            detailForm.disposeAlarmDataVoList.length !== 0
          "
        >
          处理记录
        </p>
        <div
          class="record"
          v-show="
            (alarmDetail && alarmDetail.disposeStatus !== 2) ||
            detailForm.disposeAlarmDataVoList.length !== 0
          "
        >
          <el-table
            :data="detailForm.disposeAlarmDataVoList"
            max-height="146px"
            class="no-blank"
          >
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column
              prop="operator"
              label="处理人姓名"
            ></el-table-column>
            <el-table-column
              prop="disposeTime"
              label="处理时间"
              width="180"
            ></el-table-column>
            <el-table-column prop="disposeType" label="处理类型">
              <template slot-scope="scope">{{
                disposeTypeObj[Number(scope.row.disposeType)]
              }}</template>
            </el-table-column>
            <el-table-column prop="disposeWay" label="处理方式">
              <template slot-scope="scope">{{
                disposeWayObj[Number(scope.row.disposeWay)]
              }}</template>
            </el-table-column>
            <el-table-column
              prop="disposeDescription"
              label="处理内容"
              :show-overflow-tooltip="true"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="fixed" v-if="alarmDetail && alarmDetail.disposeStatus !== 1">
        <p class="title">报警处理</p>
        <div class="deal">
          <el-form
            :inline="true"
            :model="form"
            label-width="110px"
            label-position="left"
            class="demo-form deal-form"
            :rules="rules"
            ref="alarmForm"
          >
            <el-form-item label="处理方式：" prop="disposeWay">
              <el-select
                v-model="form.disposeWay"
                placeholder="请选择"
                :disabled="notMessage"
                @change="changeWay"
              >
                <el-option
                  v-for="item in disposeWayList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="文本模板"
              prop="textTemplate"
              v-if="form.disposeWay === '1'"
            >
              <el-select
                v-model="form.textTemplate"
                placeholder="请选择"
                @change="selectTextTemplate"
              >
                <!-- <el-option value="自定义"></el-option>
                <el-option value="调度员提醒您请勿超速行驶"></el-option>
                <el-option value="调度员提醒您请勿危险驾驶"></el-option>-->
                <el-option
                  v-for="(item, key) in templateList"
                  :key="key"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="电话号码："
              prop="phone"
              v-if="form.disposeWay === '3'"
            >
              <el-input
                v-model.trim="form.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item></el-form-item>
            <el-form-item label="处理描述：" prop="disposeDescription">
              <el-input
                type="textarea"
                v-model.trim="form.disposeDescription"
                placeholder="请输入***"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div v-if="fileDialog">
      <div class="topleft">
        <el-carousel
          :interval="10000"
          indicator-position="none"
          trigger="click"
          height="600px"
        >
          <el-carousel-item v-for="item in fileForm" :key="item.time">
            <div v-if="item.alarmFileType === 0">
              <img height="80%" :src="item.alarmFilePath" alt />
              <p>
                <a
                  class="el-link el-link--default is-underline"
                  target="_blank"
                  :href="
                    item.alarmFilePath +
                    '?response-content-type=application/octet-stream'
                  "
                  download="picture"
                >
                  <i class="el-icon-download"></i>
                  <span class="el-link--inner">下载图片</span>
                </a>
              </p>
            </div>
            <div v-if="item.alarmFileType === 2">
              <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="item.fileNo"
              ></video-player>
              <p style="margin-top: 5px">
                <a
                  class="el-link el-link--default is-underline"
                  target="_blank"
                  :href="
                    item.alarmFilePath +
                    '?response-content-type=application/octet-stream'
                  "
                  download="video"
                >
                  <i class="el-icon-download"></i>
                  <span class="el-link--inner">下载视频</span>
                </a>
              </p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {
  handleDisposeAlarm,
  queryAlarmDetail,
  queryAlarmFile,
  queryMessageTemplateList,
  queryDictsByCodes
} from '@/api/lib/api.js'
import { checkPhone, formatDict } from '@/common/utils/index.js'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

import { addExcelExport } from '@/api/lib/refreshAlarm.js'
import { getCurent } from '@/common/utils/index'
export default {
  name: 'alarmDia',
  props: ['alarmID', 'alarmList', 'alarmDetail', 'isGlobalAlarm', 'isBatch'],
  data () {
    return {
      fileDialog: false,
      alarmLevelObj: {},
      disposeTypeObj: {},
      disposeWayObj: {},
      disposeWayList: [],
      templateList: [],
      detailForm: {
        alarmBaseVO: {
          cph: '',
          companyName: '',
          driverName: '',
          drivingLicense: '',
          alarmName: '',
          alarmLevel: ''
        },
        alarmDetailVoList: [],
        disposeAlarmDataVoList: []
      },
      rules: {
        disposeWay: {
          required: true,
          message: '请选择处理方式',
          trigger: 'change'
        },
        textTemplate: {
          required: true,
          message: '请选择文本模板',
          trigger: 'change'
        },
        disposeDescription: [
          { required: true, message: '请输入处理描述', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ]
      },
      form: {
        disposeWay: '1',
        terminalPhoneNo: '',
        textTemplate: '自定义',
        disposeDescription: '',
        phone: ''
      },
      exportDetailForm: {},
      downLoadStatus: false
    }
  },
  components: {
    'video-player': videoPlayer
  },
  methods: {
    // 显示附件
    onShowAnnex (row) {
      queryAlarmFile({
        fileNo: row.fileNo,
        terminalPhoneNo: this.detailForm.alarmBaseVO.terminalPhoneNo,
        alarmSignTime: row.alarmSignTime,
        alarmEndTime: row.alarmEndTime
      }).then((res) => {
        if (res.code === 1000) {
          this.fileForm = []
          if (res.data.length === 0) {
            this.$message.warning('暂未获取到附件，已下发指令获取！')
          } else {
            res.data.forEach((item) => {
              if (item.alarmFileType === 0) {
                this.fileForm.push(item)
              } else if (item.alarmFileType === 2) {
                let obj = {
                  playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                  autoplay: false, //如果true,浏览器准备好时开始回放。
                  muted: false, // 默认情况下将会消除任何音频。
                  loop: false, // 导致视频一结束就重新开始。
                  preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                  language: 'zh-CN',
                  aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                  sources: [
                    {
                      src: item.alarmFilePath, // 路径
                      type: 'video/mp4' // 类型
                    }
                  ],
                  poster:
                    'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', //你的封面地址
                  notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                  controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true //全屏按钮
                  }
                }
                item.fileNo = obj
                this.fileForm.push(item)
              }
            })
            this.fileDialog = true
            this.$emit('fileOpenDialog', this.fileDialog)
          }
        }
      })
    },
    // 选择文本模板
    selectTextTemplate (id) {
      if (id !== '自定义' && id) {
        let element = this.templateList.find((item) => item.id === id)
        if (element) this.form.disposeDescription = element.detail
      } else {
        this.form.disposeDescription = ''
      }
    },
    // 修改处理方式
    changeWay () {
      this.$refs.alarmForm.clearValidate()
    },
    // 提交
    save () {
      if (this.fileDialog) {
        this.fileDialog = !this.fileDialog
        this.$emit('fileOpenDialog', this.fileDialog)
        return
      }
      if (this.alarmDetail.disposeStatus === 1) {
        this.$emit('cancelDialog')
      } else {
        this.$refs.alarmForm.validate((valid) => {
          if (valid) {
            let disposeAlarmSendInfos = []
            if (!this.isBatch) {
              disposeAlarmSendInfos = [
                {
                  system: '0',
                  alarmNo: this.detailForm.alarmBaseVO.alarmNo,
                  alarmSignTime: this.detailForm.alarmBaseVO.alarmSignTime,
                  terminalPhoneNo: this.detailForm.alarmBaseVO.terminalPhoneNo
                }
              ]
            } else {
              //批量处理
              this.alarmList.forEach((alarm) => {
                disposeAlarmSendInfos.push({
                  system: '0',
                  alarmNo: alarm.alarmNo,
                  alarmSignTime: alarm.alarmSignTime,
                  terminalPhoneNo: alarm.terminalPhoneNo
                })
              })
            }
            const data = {
              disposeAlarmSendInfos: disposeAlarmSendInfos,
              disposeDescription: `调度员提醒您：${this.form.disposeDescription}`,
              disposeWay: this.form.disposeWay,
              phone: this.form.phone
            }
            handleDisposeAlarm(data).then((res) => {
              if (res.code === 1000) {
                this.$emit('cancelDialog', res.data)
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            return false
          }
        })
      }
    },
    // 获取报警详情
    getDetail () {
      if (this.isBatch) return //批量处理 不请求
      let alarmNos = []
      let data = {}
      if (this.isGlobalAlarm) {
        if (this.$store.state.vehicle.globalAlarmArr.length > 1) {
          this.$store.commit('cutGlobalAlarm')
        }
        data = {
          alarmNo: this.$store.state.vehicle.globalAlarmArr[0][0],
          system: '0',
          terminalPhoneNo: this.alarmDetail.terminalPhoneNo,
          alarmSignTime: this.alarmDetail.alarmSignTime
        }
      } else {
        data = {
          alarmNo: this.alarmID[0],
          system: '0',
          terminalPhoneNo: this.alarmDetail.terminalPhoneNo,
          alarmSignTime: this.alarmDetail.alarmSignTime,
          alarmEndTime: this.alarmDetail.alarmEndTime
        }
      }
      if (this.alarmID.length === 1) {
        queryAlarmDetail(data).then((res) => {
          if (res.code === 1000) {
            this.detailForm = res.data
            if (this.alarmID.length === 1) {
              this.form.terminalPhoneNo = this.detailForm.alarmBaseVO.alarmNo
              this.form.phone = this.detailForm.alarmBaseVO.phone
            } else {
              this.form.disposeWay = '3' //电话处理
            }
            this.exportDetailForm = { ...data }
          }
        })
      }
    },
    // 展示下一条全局报警
    showNextAlarm () {
      if (this.$refs.alarmForm) {
        this.$refs.alarmForm.resetFields()
      }
      this.getDetail()
    },
    //报警详情导出
    exportDetail () {
      this.downLoadStatus = true
      let data = {
        baseUrl: 'base',
        fileName: `报警详情-${getCurent()}`,
        queryParam: JSON.stringify(this.exportDetailForm),
        queryPath: `/alarm/alarmDetailExport`
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    // 获取文本模板
    getTemplateList () {
      queryMessageTemplateList().then((res) => {
        if (res.code === 1000) {
          // res.data.forEach((item) => {
          //   item.name = '调度员提醒您' + item.name
          // })
          res.data.unshift({ name: '自定义', id: '自定义' })
          this.templateList = res.data
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'BJDJ,CLLX,CLFS' }).then((res) => {
        if (res.code === 1000) {
          this.disposeWayList = res.data.CLFS
          this.alarmLevelObj = formatDict(res.data.BJDJ)
          this.disposeTypeObj = formatDict(res.data.CLLX)
          this.disposeWayObj = formatDict(res.data.CLFS)
        }
      })
    }
  },
  created () {
    this.getDetail()
    this.getDicts()
    this.getTemplateList()
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$refs.alarmForm) {
        this.$refs.alarmForm.clearValidate()
      }
    })
  },
  computed: {
    notMessage () {
      return this.isBatch
    }
  }
}
</script>

<style lang="scss" scoped>
.alarm-dia {
  height: calc(79vh - 149px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  @include themify() {
    .fixed {
      flex: 1;
    }
    .scroll {
      overflow-y: auto;
    }
    .title {
      color: themed("n7");
      font-size: 14px;
      margin-bottom: 16px;
    }
    .header-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 6px;
      span {
        margin-right: 1vw;
      }
    }
    .information,
    .record,
    .deal {
      background-color: themed("n1");
    }
    .information {
      padding: 12px;
      margin-bottom: 16px;
      .head {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        color: themed("n8");
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
        // p:nth-of-type(4n) {
        //   text-align: right;
        // }
      }
      .split-line {
        border-bottom: 1px solid;
        border-bottom-color: themed("n4");
        margin: 0 -12px 12px;
      }
    }
    .record {
      padding: 12px;
      margin-bottom: 16px;
    }
    .deal {
      padding: 16px;
    }
    .sign {
      color: themed("b4");
    }
    .pointer {
      cursor: pointer;
    }
  }
}

.batch {
  height: calc(40vh - 149px);
}
/deep/.deal-form {
  .el-form-item {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 45%;
    }
    &:last-of-type {
      width: 100%;
      display: flex;
      .el-form-item__content {
        flex: 1;
      }
    }
  }
}
/deep/.el-carousel {
  width: 600px;
  img {
    width: 30vw;
  }
}
</style>
