<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      theme: 'theme-project-gps'
    }
  },
  methods: {},
  created() {
    if (window.localStorage.getItem('YH_BF_THEME')) {
      this.theme = window.localStorage.getItem('YH_BF_THEME')
    }
    document.body.classList.add(this.theme)
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
      sessionStorage.removeItem('store')
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  mounted() {
    window.addEventListener('setItem', (e) => {
      this.theme = e.newValue
    })
  },
  destroyed() {
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    // window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  watch: {
    theme(val, oldVal) {
      document.body.classList.remove(oldVal)
      document.body.classList.add(val)
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  display: flex;
}
</style>
